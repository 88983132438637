import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import defaultprepareHeaders from '../common/prepare-header';

export const adhesionApi = createApi({
  reducerPath: "adhesion",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/adhesions", prepareHeaders: defaultprepareHeaders }),
  endpoints: (builder) => ({
    registerPrePaymentAdhesion: builder.mutation({
      query: (body) => ({
        url: "pre-payment",
        method: "POST",
        body,
      }),
      invalidatesTags: ['adhesion-creation']
    }),
    confirmAdhesion: builder.mutation({
      query: (email) => ({
        url: `${encodeURI(email)}/confirm`,
        method: "POST",
      }),
    }),
    getAdhesionStatus: builder.query({
      query: (email) => ({
        url: `${encodeURI(email)}/prepare-payment`,
        method: "GET"
      }),
      providesTags: ['adhesion-creation']
    })
  }),
});

export const { useRegisterPrePaymentAdhesionMutation, useGetAdhesionStatusQuery, useConfirmAdhesionMutation } = adhesionApi;
