// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define a service using a base URL and expected endpoints
export const authApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/auth' }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
    }),
    forgotPwd: builder.mutation({
      query: (email) => ({
        url: `/${email}/forgot-pwd`,
        method: 'POST',
      }),
    }),
    changePwd: builder.mutation({
      query: ({ body, token }) => ({
        url: '/change-pwd',
        headers: {
          authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useLoginMutation, useForgotPwdMutation, useChangePwdMutation } =
  authApi;
