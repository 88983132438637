import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const CustomBotton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "0.375rem 0.75rem",
  border: "0.0625em solid",
  lineHeight: 1.5,
  backgroundColor: "var(--bright-red)",
  borderColor: "var(--bright-red)",
  borderRadius: "6.25em",
  "&:hover": {
    backgroundColor: "#ee6889",
    // borderColor: "#E41648",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "#ebeaea",
    borderColor: "#E41648",
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

export default function CustomizedButton({
  children,
  width,
  height,
  marginLeft,
  marginTop,
  backgroundColor,
  borderColor,
  startIcon,
  endIcon,
  ...props
}) {
  return (
    <CustomBotton
      variant="contained"
      style={{ backgroundColor, borderColor, width, height, marginLeft, marginTop }}
      startIcon={startIcon}
      endIcon={endIcon}
      {...props}
    >
      {children}
    </CustomBotton>
  );
}

CustomizedButton.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  marginLeft: PropTypes.string,
  marginTop: PropTypes.string,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  type: PropTypes.string,
};
