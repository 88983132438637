import React from 'react';
import DateField from '../../../common/form/DateField';
import FormGrid from '../../../common/form/FormGrid';
import TextField from '../../../common/form/TextField';
import { useTranslation } from 'react-i18next';
import NumberField from '../../../common/form/NumberField';
import propertyDetailFields from '../../json/PropertyDetailFields.json';

const propertTypesMapping = {
  1: 'appartement',
  6: 'maison',
  5: 'villa',
  10: 'propriete_exception',
  12: 'fond_commerce',
  7: 'terrain',
  3: 'local_commercial',
  2: 'garage',
  4: 'bureau',
  8: 'local_industriel',
  11: 'immeuble',
};

const PrestationFormMandateRecap = ({ mandate }) => {
  const { t } = useTranslation();
  const fields =
    propertyDetailFields[
      propertTypesMapping[mandate?.content?.propertyType?.id]
    ];
  const fieldMap = fields
    ? fields.reduce((result, current) => {
        result[current.name] = current;
        return result;
      }, {})
    : [];

  return (
    <FormGrid className="prestations-mandate-page mandate-recap">
      <TextField
        value={mandate?.content?.propertyType?.label}
        label={t('member.mandate.edition.propertyTypeLabel')}
        disabled
      />

      {fieldMap.propertyUsage && (
        <TextField
          className="half-sized"
          value={t('member.mandate.edition.propertyUsageSelect.valueLabels.'+ mandate?.propertyDetails?.propertyUsage)}
          label={fieldMap.propertyUsage.label}
          disabled
        />
      )}

      {fieldMap.typologyId && (
        <TextField
          className="half-sized"
          value={mandate?.propertyDetails?.typology?.label}
          label={fieldMap.typologyId.label}
          disabled
        />
      )}

      {fieldMap.constructionDate && (
        <DateField
          className="half-sized"
          value={mandate?.propertyDetails?.constructionDate}
          inputFormat="dd/MM/yyyy"
          label={fieldMap.constructionDate.label}
          disabled
        />
      )}

      {fieldMap.constructionPermitDate && (
        <DateField
          className="half-sized"
          value={mandate?.propertyDetails?.constructionPermitDate}
          inputFormat="dd/MM/yyyy"
          label={fieldMap.constructionPermitDate.label}
          disabled
        />
      )}

      {fieldMap.landArea && (
        <NumberField
          className="half-sized"
          value={mandate?.propertyDetails?.landArea}
          label={fieldMap.landArea.label}
          unit={'m²'}
          unitPosition="end"
          disabled
        />
      )}

      {fieldMap.livingArea && (
        <NumberField
          className="half-sized"
          value={mandate?.propertyDetails?.livingArea}
          label={fieldMap.livingArea.label}
          unit={'m²'}
          unitPosition="end"
          disabled
        />
      )}

      {fieldMap.usefulArea && (
        <NumberField
          className="half-sized"
          value={mandate?.propertyDetails?.usefulArea}
          label={fieldMap.usefulArea.label}
          unit={'m²'}
          unitPosition="end"
          disabled
        />
      )}

      {fieldMap.bedroomsCount && (
        <NumberField
          className="half-sized"
          value={mandate?.propertyDetails?.bedroomsCount}
          label={fieldMap.bedroomsCount.label}
          disabled
        />
      )}

      {fieldMap.roomsCount && (
        <NumberField
          className="half-sized"
          value={mandate?.propertyDetails?.roomsCount}
          label={fieldMap.roomsCount.label}
          disabled
        />
      )}
    </FormGrid>
  );
};

export default PrestationFormMandateRecap;
