import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import { format } from "date-fns";

export function exportDocument(mandat, member) {
  const { url, data } = formattingData(mandat, member);
  PizZipUtils.getBinaryContent(url, (error, content) => {
    if (error) {
      throw error;
    }
    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
      nullGetter: () => "………………………………………",
    });
    doc.setData(data);
    try {
      doc.render();
    } catch (error) {
      console.error(JSON.stringify({ error: error }, handleRenderErrors));

      if (error.properties && error.properties.errors instanceof Array) {
        const errorMessages = error.properties.errors
          .map(function (error) {
            return error.properties.explanation;
          })
          .join("\n");
        console.error("errorMessages", errorMessages);
      }
      throw error;
    }
    const out = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    saveAs(
      out,
      `mandat_${data.mandatType ? data.mandatType : ""}_${
        data.mandateNumber
      }.docx`
    );
  });
}

const handleRenderErrors = (key, value) => {
  if (value instanceof Error) {
    return Object.getOwnPropertyNames(value).reduce(function (error, key) {
      error[key] = value[key];
      return error;
    }, {});
  }
  return value;
};

const formattingData = (mandat, member) => {
  const data = {
    mandatType: mandat.content.mandateType,
    mandateNumber: mandat.content.mandateNumber,
    firstName: member.prenom,
    lastName: member.nom,
    office: member.adherent.cabinet.barreau.nom,
    address: `${member.adherent.adresse} - ${member.adherent.ville} ${member.adherent.codePostal}`,
    phone: member.adherent.telephonePortable ?? member.adherent?.telephoneFixe,
    email: member.email,
    nature: mandat.content.propertyType.label,
    propertyAddress: `${mandat.content.propertyAddress} - ${mandat.content.city} ${mandat.content.postalCode}`,
    isNaturalOwner:
      !!mandat?.propertyDetails?.naturalOwners &&
      mandat?.propertyDetails?.naturalOwners.length > 0,
    isLegalOwner: !!mandat?.propertyDetails?.legalOwner,
    isNowOwner:
      (!mandat?.propertyDetails?.naturalOwners ||
        mandat?.propertyDetails?.naturalOwners.length === 0) &&
      (!mandat?.propertyDetails?.legalOwner ||
        mandat?.propertyDetails?.legalOwner.length === 0),
    naturalOwners: mandat.propertyDetails?.naturalOwners?.map((o) => ({
      ...o,
      birthDate: format(new Date(o.birthDate), "dd/MM/yyyy"),
      address: `${o.address} - ${o.city} ${o.postalCode}`,
    })),
    legalOwnerFirstName: mandat.propertyDetails?.legalOwner?.agentFirstName,
    legalOwnerLastName: mandat.propertyDetails?.legalOwner?.agentLastName,
    legalOwnerCompany: mandat.propertyDetails?.legalOwner?.companyName,
    legalOwnerType: mandat.propertyDetails?.legalOwner?.type,
    legalOwnerSiret: mandat.propertyDetails?.legalOwner?.siret,
    legalOwnerAddress: `${mandat.propertyDetails?.legalOwner?.headquartersAddress}, ${mandat.propertyDetails?.legalOwner?.headquartersCity} ${mandat.propertyDetails?.legalOwner?.headquartersPostalCode}`,
    roomsCount: mandat.propertyDetails?.roomsCount,
    landArea: mandat.propertyDetails?.landArea ?? mandat.propertyDetails?.usefulArea ?? mandat.propertyDetails?.livingArea,
    price: mandat.content.estimatedPrice
      ? mandat.content.estimatedPrice * (1 + (mandat.content.mandateRate ?? 0))
      : undefined,
  };
  const url =
    mandat.content.mandateType === "EXCLUSIF"
      ? "/template-mandat/template-mandate-exclusif.docx"
      : "/template-mandat/template-mandate-non-exclusif.docx";

  return { url, data };
};
