import React from "react";

function CreateDossierPage() {
    return (
        <div className="create-dossier-page">
            Create dossier
        </div>
    );
}

export default CreateDossierPage;