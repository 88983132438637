import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "../../api/auth/auth.api";
import TokenJWT from "../../tools/TokenJWT";

const initialState = {
  value: null,
  token: null,
  loading: false,
  error: null,
  errorMessage: "",
  success: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state, action) => {
      state.token = null;
      state.success = false;
      state.value = null;
    },
    resetError: (state, action) => {
      state.error = null;
      state.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchPending, (state) => {
      state.loading = true;
      state.value = null;
      state.token = null;
      state.success = false;
      state.error = false;
      state.errorMessage = "";
    });
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload?.id) {
          state.value = payload;
          state.token = payload?.token;
          state.loading = false;
          state.success = true;
        } else {
          state.loading = false;
          state.error = true;
          state.errorMessage = payload?.data?.message ?? 'error.unknown';
          state.success = false;
        }
      },
    );
    builder.addMatcher(
      authApi.endpoints.login.matchRejected,
      (state, { payload }) => {
        state.error = true;
        state.errorMessage = payload?.data?.message ?? payload;
        state.loading = false;
        state.success = false;
      },
    );
  },
});

export const { resetError, logout } = userSlice.actions;
export const selectToken = (state) => state.user.token;
export const selectUser = (state) => state.user;
export const selectUserData = (state) => state.user.value;
export const isUserLoggedIn = (state) => state.user.token !== null && TokenJWT.isValid(state.user.token, new Date());
export default userSlice.reducer;
