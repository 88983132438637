// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import defaultprepareHeaders from '../common/prepare-header';

// Define a service using a base URL and expected endpoints
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/user", prepareHeaders: defaultprepareHeaders }),
  endpoints: (builder) => ({
    getUser: builder.query({
        query: (id) => `/${id}`,
    }),
  }),
});

export const { useGetUserQuery } = userApi;
