import { Container } from "@mui/system";
import Bubble from "../../bubble/Bubble";
import AdhesionHeader from "./../header/AdhesionHeader";
import "./AdhesionFormLayout.css";

export const steps = {
  personalData: "personalDataStep",
  miaData: "miaDataStep",
  paymentData: "paymentDataStep",
};

const AdhesionFormLayout = ({ step, children }) => (
  <div className="page-content adhesion-form-page" style={{ position: "relative" }}>
    <AdhesionHeader step={step} />
    <Container>{children}</Container>
    <Bubble />
  </div>
);

export default AdhesionFormLayout;
