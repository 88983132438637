import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextField from '../../components/common/form/TextField';
import Header from '../../components/common/header/Header';
import {
  useDefaultFieldProps,
} from '../../components/common/form/utils';
import './LoginPage.css';
import { Link, useNavigate } from 'react-router-dom';
import CustomizedButton from '../../components/common/CustomizedButton';
import { useLoginMutation } from '../../api/auth/auth.api';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/userSlice';
import QueriesErrors from '../../components/common/error/QueriesErrors';

const LoginPage = () => {
  const { t } = useTranslation();
  const [login, loginQuery] = useLoginMutation();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const { defaultFieldProps } = useDefaultFieldProps(loginForm, setLoginForm);

  useEffect(() => {
    if (user.success && !user.error) {
      navigate("/member");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    login(loginForm);
  };

  return (
    <>
      <Header title={t("login.title")}></Header>
      <Grid
        container
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        minHeight={"100vh"}
      >
        <Grid className="loginpage" item md={8} paddingBottom={"20em"}>
          <form className="login-form" onSubmit={handleSubmit}>
            <Grid
              container
              flexDirection={"column"}
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Grid item>
                <QueriesErrors queries={[loginQuery]} sx={{ maxWidth: '63em', whiteSpace: 'pre-line' }} />
              </Grid>
              <Grid
                item
                container
                flexDirection={"column"}
                justifyContent={"space-around"}
                minHeight={"15em"}
                md={6}
              >
                <Grid item>
                  <TextField
                    type="email"
                    {...defaultFieldProps("email")}
                    label={t("login.emailLabel")}
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    className="half-sized"
                    type="password"
                    {...defaultFieldProps("password")}
                    label={t("login.passwordLabel")}
                    variant="outlined"
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignContent={"flex-end"}
                justifyContent={"flex-end"}
                md={6}
              >
                <Link to={'/forgot-pwd'}>{t("login.forgetPassword")}</Link>
              </Grid>
              <Grid
                item
                container
                justifyContent={"center"}
                marginTop={"5em"}
                md={6}
              >
                <CustomizedButton
                  height="2.1875em"
                  type="submit"
                >
                  {t("login.connexion")}
                </CustomizedButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default LoginPage;
