import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import Prestators from './prestators/Prestators';
import { useDefaultFieldProps } from '../../../common/form/utils';

const PrestationCheckBox = ({
  prestation,
  value = { selected: false },
  setValue,
  disabled = false,
}) => {
  const isSelected = !!value.selected;
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setValue({ ...value, selected: true });
    } else {
      setValue({ ...value, selected: false });
    }
  };
  const { defaultSubFormProps } = useDefaultFieldProps(value, setValue);
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox checked={isSelected} onChange={handleCheckboxChange} disabled={disabled}/>
        }
        label={prestation.label + ` (${prestation.price}€ HT)`}
      />
      {isSelected && (
        <Prestators
          prestators={prestation.prestators}
          {...defaultSubFormProps('selectedPrestataire')}
          name={`prestation-${prestation.id}`}
        />
      )}
    </>
  );
};

export default PrestationCheckBox;
