import React, { useEffect } from 'react';
import styled from 'styled-components';
import PrestatorCard from './PrestatorCard';
import { useTranslation } from 'react-i18next';

const PrestatorsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
`;

const Prestators = ({
  prestators,
  value: selectedPrestatorId,
  setValue: setSelectedPrestatorId,
  name,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (prestators.length === 1) {
      setSelectedPrestatorId(prestators[0].id);
    }
  }, [prestators]);

  return (
    <>
      <input
        type="text"
        name={`${name}-pretator`}
        style={{ opacity: 0, float: 'left', width: 0, height: 0 }}
        value={selectedPrestatorId ?? 'none'}
        title={t('forms.validationMsg.prestatorSelection')}
        onChange={() => {}} //Avoid error without switch to readonly (that will skip the validation)
        pattern="\d+"
      />

      <PrestatorsList>
        {prestators.map(({ id, ...prestator }) => {
          const isSelected = id === selectedPrestatorId;
          return (
            <PrestatorCard
              key={id}
              {...prestator}
              isSelected={isSelected}
              onClick={() => setSelectedPrestatorId(id)}
            />
          );
        })}
      </PrestatorsList>
    </>
  );
};

export default Prestators;
