import React from "react";
import "./AdvantagesItem.css";

const AdvantagesItem = ({ description }) => {
  return (
    <div className="advantages-item">
      <div className="description">{description}</div>
    </div>
  );
};

export default AdvantagesItem;
