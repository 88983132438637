import React, { useEffect, useRef } from 'react';
import AdhesionFormLayout, {
  steps,
} from '../../../components/adhesion-form/layout/AdhesionFormLayout';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetAdhesionStatusQuery,
} from '../../../api/adhesion/adhesion.api';
import { LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextField from '../../../components/common/form/TextField';
import DateField from '../../../components/common/form/DateField';
import NumberField from '../../../components/common/form/NumberField';
import AcceptanceField from '../../../components/common/form/AcceptanceField';
import './AdhesionFormMiaDataPage.css';
import statusDocs from '../../../assets/pdf/StatutsMIAv2.pdf';
import rulesDocs from '../../../assets/pdf/reglementMIA.pdf';
import FormGrid from './../../../components/common/form/FormGrid';
import SystemPayForm from '../../../components/common/payment/SytempayForm';

const MiaDataStep = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { email } = useParams();
  const { data, isSuccess, isLoading, isError } =
    useGetAdhesionStatusQuery(email);

  const { recap, paymentForm } = data ?? {};

  const acceptanceFormRef = useRef();

  // ---- NAVIGATION ---- //

  useEffect(() => {
    if (isError) {
      navigate(`/adhesion`);
    }
  }, [isError]);

  return (
    <AdhesionFormLayout step={steps.miaData}>
      {isLoading && <LinearProgress />}
      {isSuccess && (
        <>
          <FormGrid
            id="acceptance-form"
            className="adhesion-page acceptations"
            ref={acceptanceFormRef}
            onSubmit={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            <TextField
              className="half-sized"
              value={recap.name}
              label={t('adhesion.acceptances.nameLabel')}
              disabled
            />
            <TextField
              className="half-sized"
              value={recap.firstName}
              label={t('adhesion.acceptances.firstNameLabel')}
              disabled
            />
            <DateField
              className="half-sized"
              value={recap.registrationDate}
              inputFormat="dd/MM/yyyy"
              label={t('adhesion.acceptances.registrationDateLabel')}
              disabled
            />
            <DateField
              className="half-sized"
              value={recap.endDate}
              inputFormat="dd/MM/yyyy"
              label={t('adhesion.acceptances.endDateLabel')}
              disabled
            />
            <NumberField
              value={recap.yearAmountHT}
              label={t('adhesion.acceptances.yearAmountHTLabel')}
              unit={'€'}
              disabled
            />
            <NumberField
              value={recap.yearAmountTTC}
              label={t('adhesion.acceptances.yearAmountTTCLabel')}
              unit={'€'}
              disabled
            />
            <div className="fields-group">
              <AcceptanceField
                required
                docs={statusDocs}
                criteriaLabel={t('adhesion.acceptances.statusCriteria')}
              />
              <AcceptanceField
                required
                docs={rulesDocs}
                criteriaLabel={t('adhesion.acceptances.rulesCriteria')}
              />
            </div>
          </FormGrid>
          <SystemPayForm
            body={paymentForm}
            sendLabel={`${t('adhesion.acceptances.submit')}`}
            disabled={!isSuccess}
            onSubmit={(e) => {
              if (acceptanceFormRef.current.checkValidity() === false) {
                acceptanceFormRef.current.reportValidity()
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          />
        </>
      )}
    </AdhesionFormLayout>
  );
};

export default MiaDataStep;
