import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  useListPrestationsDoneQuery,
  useListPrestationByCategoryQuery,
} from '../../../../api/prestation/prestation.api';
import LinearProgress from '@mui/material/LinearProgress';
import QueriesErrors from '../../../common/error/QueriesErrors';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import { useDefaultFieldProps } from '../../../common/form/utils';
import FormGroup from '@mui/material/FormGroup';
import PrestationCheckBox from './PrestationCheckBox';

const PrestationTitle = styled.h4`
  margin-bottom: 0;
`;
const RedLink = styled.a`
  color: var(--dark-red);
  font-weight: bold;

  :hover {
    color: var(--bright-red);
  }
`;

const CategoryAccordeon = ({ label, key = undefined, children }) => (
  <Accordion key={key}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>{label}</AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);

const fromLstToMap = (lst) =>
  lst.reduce(
    (result, current) => ({
      ...result,
      [current.prestationTarifId]: {
        selected: true,
        selectedPrestataire: current.prestataireId,
      },
    }),
    {},
  );

const fromMapToLst = (map) =>
  Object.entries(map)
    .filter(([_key, value]) => value?.selected)
    .map(([prestationTarifId, { selectedPrestataire }]) => ({
      prestationTarifId: prestationTarifId,
      prestataireId: selectedPrestataire,
    }));

const PrestationPerUnitSubForm = ({
  mandate,
  lst: selectedPrestationForm = [],
  setLst: setSelectedPrestationForm,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'member.mandate.prestation',
  });

  const {
    data: prestationDone,
    isLoading: isPrestationDoneQueryLoading,
    isSuccess: isPrestationDoneQuerySuccess,
    ...prestationDoneQuery
  } = useListPrestationsDoneQuery({ mandateId: mandate.id });
  const {
    data: prestationByCategory,
    isLoading: isPrestationQueryLoading,
    isSuccess: isPrestationQuerySuccess,
    ...prestationQuery
  } = useListPrestationByCategoryQuery({
    postalCode: mandate.content.postalCode,
    typologyId: mandate.propertyDetails.typology?.id,
    propertyTypeId: mandate.content.propertyType.id,
  });
  const isQueriesLoading =
    isPrestationDoneQueryLoading || isPrestationQueryLoading;
  const isQueriesSuccess =
    isPrestationDoneQuerySuccess || isPrestationQuerySuccess;

  const isPrestationAlreadyCommanded = ({ prestationTypeBienTarifId }) =>
    !!(prestationDone?.perUnitDones ?? []).find(
      (done) => done.prestationTypeBienTarifId === prestationTypeBienTarifId,
    );

  const [prestationsMap, setPrestationsMap] = useState(
    fromLstToMap(selectedPrestationForm),
  );
  useEffect(() => {
    setSelectedPrestationForm(fromMapToLst(prestationsMap));
  }, [prestationsMap]);
  const { defaultSubFormProps } = useDefaultFieldProps(
    prestationsMap,
    setPrestationsMap,
  );

  const handleSpecialCategoryCase = ({ id }) => {
    let infoVarName;
    switch (id) {
      case 5:
        infoVarName = 'visite';
        break;
      case 6:
        infoVarName = 'adds';
        break;
      default:
        return (
          <Alert severity="warning" sx={{ whiteSpace: 'pre-wrap' }}>
            {t('warning.callPrestator')}
          </Alert>
        );
    }
    /** @type {string[]} */
    const infos = t([`perUnit.info.${infoVarName}`], { returnObjects: true });
    return (
      <>
        {infos.map((info, idx) => (
          <Alert severity="info" sx={{ whiteSpace: 'pre-wrap' }} key={idx}>
            {info}
          </Alert>
        ))}
      </>
    );
  };

  return (
    <>
      <PrestationTitle>{t('perUnit.label')}</PrestationTitle>
      <QueriesErrors queries={[prestationQuery, prestationDoneQuery]} />
      {isQueriesLoading && <LinearProgress />}
      {isQueriesSuccess && (
        <div className="fields-group">
          {(isPrestationQuerySuccess && !prestationByCategory?.length && (
            <Alert severity="info">{t('perUnit.none')}</Alert>
          )) || [
            ...(prestationByCategory ?? [])
              .filter(({ id }) => id !== 6) //TODO lot2: handle publicity
              .filter(({ id }) => id !== 2 && id !== 4) //TODO Fix according to MIA-72. Once the old website is unused, block the visibility directly in db (categorie_prestation.visible = false) and remove this line
              .map((category) => (
                <CategoryAccordeon label={category.label} key={category.id}>
                  {handleSpecialCategoryCase(category)}
                  <FormGroup>
                    {category.prestations.map((prestation, index) => (
                      <PrestationCheckBox
                        prestation={prestation}
                        disabled={isPrestationAlreadyCommanded(prestation)}
                        {...defaultSubFormProps(
                          prestation.prestationTypeBienTarifId,
                        )}
                        key={category.id}
                      />
                    ))}
                  </FormGroup>
                </CategoryAccordeon>
              )),
            <CategoryAccordeon label={'Publicité'} key={'tmp'}> {/** //TODO lot2: remove once publicity handled */}
              <Alert severity="warning">
                {t('perUnit.unhandled')}
                <RedLink href="https://intranet.marcheimmobilierdesavocats.com/">
                  https://intranet.marcheimmobilierdesavocats.com/
                </RedLink>
              </Alert>
            </CategoryAccordeon>,
          ]}
        </div>
      )}
    </>
  );
};

export default PrestationPerUnitSubForm;
