import React from "react";
import { useTranslation } from "react-i18next";
import { useGetMandatesQuery } from "../../api/mandate/mandate.api";
import Alert from "@mui/material/Alert";
import MandateCard from "./../../components/mandate/MandateCard";
import { CardsGrid } from "../../components/common/Common";
import SkeletonCard from "../../components/common/skeleton/SkeletonCard";
import QueriesErrors from '../../components/common/error/QueriesErrors';

const ViewMandatesPage = ({ filterMandates = () => true }) => {
  const { t } = useTranslation();
  const {
    data: mandates,
    isSuccess,
    isLoading,
    ...mandatesQuery
  } = useGetMandatesQuery();
  const mandateToDisplay = (mandates ?? []).filter(filterMandates).sort(recentsFirst);
  const noData = isSuccess && mandateToDisplay.length === 0;

  return (
    <>
      {isLoading && (
        <CardsGrid>
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
        </CardsGrid>
      )}
      {noData && <Alert severity='info'>{t('member.mandate.view.none')}</Alert>}
      <QueriesErrors queries={[mandatesQuery]} />
      {isSuccess && (
        <CardsGrid>
          {mandateToDisplay.map((mandat) => (
            <MandateCard key={mandat.id} mandate={mandat} />
          ))}
        </CardsGrid>
      )}
    </>
  );
};

const recentsFirst = (a, b) => b.id - a.id;

export default ViewMandatesPage;
