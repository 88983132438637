import React from 'react';
import { Card, CardActionArea, CardContent } from '@mui/material';
import './PrestatorCard.css';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Title = styled.div`
  font-weight: bold;
  padding-bottom: 1.5em;
`;

const Stats = styled.div`
  padding-bottom: 1.5em;
`;

const Details = styled.div`
  padding-bottom: 0.5em;
  text-overflow: ellipsis;
  white-space: pre-wrap;
`;

const PrestatorCard = ({
  name,
  companyName,
  details = '',
  nbPrestationsDone,
  isSelected = false,
  onClick,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'member.mandate.prestation.prestators',
  });
  return (
    <Card className={isSelected ? 'prestator-card active' : 'prestator-card'}>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Title>
            {!!name && (
              <>
                {name} <br />
              </>
            )}
            {companyName}
          </Title>
          <Stats>{t('stats', { nbPrestationsDone })}</Stats>
          <Details>{details}</Details>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default PrestatorCard;
