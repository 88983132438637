import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./NavBarItem.css";
import { Divider, Grid } from "@mui/material";

const NavItem = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  color: #383737;
  font-size: 1rem;
  line-height: 1.5625em;
`;

const Underline = styled(Divider)`
  border-width: 0.1em !important;
  border-color: #383737 !important;
  margin-top: 1em !important;
  @media screen and (max-width: 900px) {
      display: none;
  }
`;

function NavBarItem({ label, link }) {
  const { t } = useTranslation();
  return (
    <Grid item lg={"auto"} md={"auto"} sm={12} xs={12}>
      <Grid
        container
        flexDirection={"column"}
        justifyContent={"space-between"}
        className="navbar-item"
      >
        <Grid item>
          <NavItem href={`${link}`} className="bold">
            {t(`home.header.${label}`)}
            <Underline />
          </NavItem>
        </Grid>
      </Grid>
    </Grid>
  );
}

NavBarItem.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default NavBarItem;
