import React from 'react';
import './form.css';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from './TextField';

const NumberField = ({
  className = '',
  InputProps = {},
  unit = '',
  unitPosition = 'start',
  onChange = (e) => {},
  ...props
}) => {
  if (unit && unitPosition == 'end') {
    InputProps = {
      endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
      ...InputProps,
    };
  }

  if (unit && unitPosition == 'start') {
    InputProps = {
      startAdornment: <InputAdornment position="start">{unit}</InputAdornment>,
      ...InputProps,
    };
  }

  return (
    <TextField
      className={'amount-field ' + (className ?? '')}
      type="number"
      InputProps={InputProps}
      onChange={(e) => {
        onChange({
          ...e,
          target: { ...e.target, value: Number.parseFloat(e.target.value) },
        });
      }}
      {...props}
    />
  );
};

export default NumberField;
