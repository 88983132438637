import React from 'react';
import './DocumentsPage.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '../../components/common/form/Button';
import DownloadIcon from '@mui/icons-material/Download';
import styled from 'styled-components';
import { useGetDocumentsQuery } from '../../api/documents/documents.api';
import SkeletonForm from '../../components/common/skeleton/SkeletonForm';
import { useTranslation } from 'react-i18next';

/**
 * WARNING : files are not stored in the public folder of the server
 * We used currently the old website https://intranet.marcheimmobilierdesavocats.com/ to store the files
 * A migration will be needed when the oldwebsite will be shut down !!
 */

const DocumentsPage = () => {
  // @ts-ignore
  const { data, isLoading } = useGetDocumentsQuery();
  const { t } = useTranslation('', { keyPrefix: 'member.documents' });

  return (
    <div className="documents-page">
      {isLoading && <SkeletonForm />}
      {data &&
        [...data].sort(sortBy('nom')).map((categorie) => {
          return (
            <div key={categorie.nom}>
              <h3>{categorie.nom}</h3>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell width="80%">{t('titre')}</TableCell>
                      <TableCell width="20%">{t('updateDate')}</TableCell>
                      <TableCell width="10%"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...categorie.documents]
                      .sort(sortBy('nom'))
                      .map((document) => {
                        const options = {
                          day: 'numeric',
                          month: 'numeric',
                          year: 'numeric',
                        };
                        const date_ajout = new Date(
                          document.dateAjout,
                        ).toLocaleDateString('fr-FR', options);
                        const linkAvailable =
                          !!document.fichierDocuments[0]?.nomFichier;
                        return (
                          <TableRow
                            key={document.nom}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {document.nom}
                            </TableCell>
                            <TableCell>{date_ajout}</TableCell>
                            <TableCell>
                              {linkAvailable && (
                                <a
                                  href={
                                    'https://intranet.marcheimmobilierdesavocats.com/resources/documents/' +
                                    document.fichierDocuments[0].nomFichier
                                  }
                                  target="_blank"
                                  download
                                >
                                  <DownloadButton startIcon={<DownloadIcon />}>
                                    {t('voir')}
                                  </DownloadButton>
                                </a>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          );
        })}
    </div>
  );
};

const DownloadButton = styled(Button)`
  font-size: 0.8em !important;
  padding-right: 1.5em !important;
  padding-left: 1em !important;
`;

const sortBy = (key) => ((o1, o2) =>  (o1[key] ?? '').localeCompare(o2[key] ?? ''))

export default DocumentsPage;
