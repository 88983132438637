import React from 'react';
import Button from '../../common/form/Button';
import { useTranslation } from 'react-i18next';
import TextField from '../../common/form/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DateField from '../../common/form/DateField';

const NotZeroValidation = ({ value, errorMsg }) => (
  <input
    className="hidden-validation"
    name="nb-natural-owner"
    style={{ width: 0, height: 0, opacity: 0 }}
    value={value ?? 0}
    onChange={() => {}}
    pattern={'(?!^0$)^\\d+$'}
    title={errorMsg}
  />
);

const SubNaturalOwnerForm = ({
  lst: owners = [],
  setLst: setOwners,
  defaultFieldProps,
}) => {
  const { t } = useTranslation();

  return (
    <div className="fields-group owners-form">
      <Button
        className="add-more-button"
        fullWidth
        onClick={() => {
          setOwners([...owners, { birthDate: null }]);
        }}
      >
        {t('member.mandate.edition.owners.natural.addNatural')}
        <NotZeroValidation value={owners.length} errorMsg={t('forms.validationMsg.ownersNb')}/>
      </Button>
      <div className="owners-form-content">
        {owners.map((_owner, idx) => (
          <Accordion key={idx}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <label>
                {t('member.mandate.edition.owners.natural.title')} {idx + 1}
              </label>
            </AccordionSummary>
            <AccordionDetails className="sub-form owner-form">
              <TextField
                className="half-sized"
                {...defaultFieldProps('lastName', idx)}
                label={t('member.mandate.edition.owners.natural.nameLabel')}
                required
              />
              <TextField
                className="half-sized"
                {...defaultFieldProps('firstName', idx)}
                label={t(
                  'member.mandate.edition.owners.natural.firstNameLabel',
                )}
                required
              />
              <TextField
                className="half-sized"
                type="tel"
                {...defaultFieldProps('phone', idx)}
                label={t('member.mandate.edition.owners.natural.phoneLabel')}
              />
              <TextField
                className="half-sized"
                type="email"
                {...defaultFieldProps('email', idx)}
                label={t('member.mandate.edition.owners.natural.emailLabel')}
              />
              <TextField
                {...defaultFieldProps('nationality', idx)}
                label={t(
                  'member.mandate.edition.owners.natural.nationalityLabel',
                )}
              />
              <TextField
                className="half-sized"
                {...defaultFieldProps('birthPlace', idx)}
                label={t(
                  'member.mandate.edition.owners.natural.birthPlaceLabel',
                )}
              />
              <DateField
                className="half-sized"
                {...defaultFieldProps('birthDate', idx)}
                inputFormat="dd/MM/yyyy"
                label={t(
                  'member.mandate.edition.owners.natural.birthDateLabel',
                )}
              />
              <TextField
                {...defaultFieldProps('address', idx)}
                label={t('member.mandate.edition.owners.natural.addressLabel')}
                required
              />
              <TextField
                className="half-sized"
                {...defaultFieldProps('postalCode', idx)}
                label={t(
                  'member.mandate.edition.owners.natural.postalCodeLabel',
                )}
                required
              />
              <TextField
                className="half-sized"
                {...defaultFieldProps('city', idx)}
                label={t('member.mandate.edition.owners.natural.cityLabel')}
                required
              />
              <div className="actions">
                <Button
                  className="remove-button"
                  onClick={() => {
                    setOwners([
                      ...owners.slice(0, idx),
                      ...owners.slice(idx + 1, owners.length),
                    ]);
                  }}
                >
                  {t('member.mandate.edition.owners.natural.removeNatural')}
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default SubNaturalOwnerForm;
