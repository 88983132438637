import React from 'react';
import LogoMIA from '../../components/common/logo/LogoMIA';
import styled from 'styled-components';

const RedLink = styled.a`
  color: var(--dark-red);
  font-weight: bold;

  :hover {
    color: var(--bright-red);
  }
`;

function DashboardPage() {
  return (
    <div className="dashboard-page">
      <p>Bienvenue sur le site MVP du MIA !</p>
      <p>
        Nous tenons à vous informer que le site que vous utilisez actuellement
        est encore en version MVP (Minimum Viable Product) et que certaines
        fonctionnalités ne sont pas encore disponibles. Nous travaillons
        activement à l'amélioration de notre site et nous sommes désolés pour
        les désagréments que cela peut occasionner.
      </p>
      <p>
        Nous tenons à vous informer que la diffusion des annonces n'est pas
        encore disponible sur le site actuel et que pour utiliser cette
        fonctionnalité, vous devez retourner sur le site{' '}
        <RedLink href="https://intranet.marcheimmobilierdesavocats.com/">
          https://intranet.marcheimmobilierdesavocats.com/
        </RedLink>
      </p>
      <p>
        Nous vous remercions de votre compréhension et nous sommes impatients de
        vous offrir une expérience utilisateur plus complète.
      </p>
      <LogoMIA />
    </div>
  );
}

export default DashboardPage;
