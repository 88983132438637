import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import defaultprepareHeaders from '../common/prepare-header';

export const prestationApi = createApi({
  reducerPath: 'prestation',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/prestations',
    prepareHeaders: defaultprepareHeaders,
  }),
  endpoints: (builder) => ({
    listPrestationByCategory: builder.query({
      query: (query) => ({
        url: `by-category`,
        params: query,
        method: 'GET',
      }),
    }),
    listPackByCategory: builder.query({
      query: (query) => ({
        url: `packs/by-category`,
        params: query,
        method: 'GET',
      }),
    }),
    listPrestationsDone: builder.query({
      query: (query) => ({
        url: `done`,
        params: query,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useListPrestationByCategoryQuery,
  useListPackByCategoryQuery,
  useListPrestationsDoneQuery,
} = prestationApi;
