import React from 'react'
import { useSelector } from "react-redux"
import LoginPage from './pages/login/LoginPage';
import { isUserLoggedIn } from "./redux/slices/userSlice";

const ProtectedRoute = ({ children }) => {
    const isLogin = useSelector(isUserLoggedIn);
    if (isLogin) {
        return children
    } else {
        return <LoginPage />
    }
};

export default ProtectedRoute;