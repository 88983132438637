import React from "react";
import "./form.css";
import NumberField from "./NumberField";

const PercentField = ({
  value,
  onChange,
  decimalNumber = 0,
  className = "",
  ...props
}) => (
  <NumberField
    className={"percent-field " + (className ?? "")}
    type="number"
    unit="%"
    value={value === "" ? "" : (value * 100).toFixed(decimalNumber)}
    onChange={(e) => {
      e.target.value /= 100;
      onChange(e);
    }}
    {...props}
  />
);

export default PercentField;
