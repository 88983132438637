import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import {
  useGetMandateQuery,
  useRegisterPrePaymentPrestationOrderMutation,
} from '../../../../api/mandate/mandate.api';
import Button from '../../../common/form/Button';
import FormGrid from '../../../common/form/FormGrid';
import LinearProgress from '@mui/material/LinearProgress';
import QueriesErrors from '../../../common/error/QueriesErrors';
import PrestationPackSubForm from './PrestationPackSubForm';
import PrestationPerUnitSubForm from './PrestationPerUnitSubForm';
import './PrestationsForm.css';
import PrestationFormMandateRecap from './PrestationFormMandateRecap';
import { onInvalidSubAccordionForm, useDefaultFieldProps } from '../../../common/form/utils';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import TextField from '../../../common/form/TextField';

export const WarningMessage = ({ message, ...props }) => {
  return (
    <Alert severity="warning" {...props}>
      {message}
    </Alert>
  );
};

const PrestationsForm = ({ mandateId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // ---- QUERIES ---- //

  const {
    data: mandate,
    isLoading: isMandateQueryLoading,
    isSuccess: isMandateQuerySuccess,
    ...mandateQuery
  } = useGetMandateQuery(mandateId);

  // ---- MUTATIONS ---- //

  const [
    orderPrestation,
    { isSuccess: isOrderSuccess, isLoading: isOrderLoading, ...orderQuery },
  ] = useRegisterPrePaymentPrestationOrderMutation();

  useEffect(() => {
    if (isOrderSuccess) {
      navigate(`/member/mandates/${mandateId}/order/recap`);
    }
  }, [isOrderSuccess]);

  // ---- FORM DATA & LOGIC ---- //

  const [formData, setFormData] = useState({
    ownerContact: '',
    pack: undefined,
    prestationPerUnit: [],
  });

  const { defaultSubLstFieldProps, defaultSubFormProps, defaultFieldProps } =
    useDefaultFieldProps(formData, setFormData);

  const handleSubmit = (e) => {
    e.preventDefault();
    orderPrestation({ mandateId, body: formData });
    return false;
  };

  return (
    <>
      <QueriesErrors queries={[mandateQuery]} />
      {(isMandateQueryLoading && <LinearProgress />) || (
        <>
          {!mandate?.propertyDetails?.isDescriptifDone && (
            <WarningMessage
              sx={{ mb: '1em' }}
              message={t(
                'member.mandate.prestation.warning.propertyDetailsNeeded',
              )}
            />
          )}
          <PrestationFormMandateRecap mandate={mandate} />
          {isMandateQuerySuccess &&
            mandate?.propertyDetails?.isDescriptifDone && (
              <FormGrid
                className="prestation-form"
                onSubmit={handleSubmit}
                style={{ marginTop: '1.5em' }}
                onInvalid={onInvalidSubAccordionForm}  
              >
                <TextField
                  {...defaultFieldProps('ownerContact')}
                  label={t('member.mandate.prestation.ownerDetailsLabel')}
                  minRows={3}
                  multiline
                />
                <PrestationPackSubForm
                  mandate={mandate}
                  {...defaultSubFormProps('pack')}
                />
                <PrestationPerUnitSubForm
                  {...defaultSubLstFieldProps('prestationPerUnit')}
                  mandate={mandate}
                />
                <div className="actions">
                  <Button type="submit">
                    {t('member.mandate.prestation.submit')}
                  </Button>
                  {isOrderLoading && <CircularProgress />}
                  <QueriesErrors queries={[orderQuery]} />
                </div>
              </FormGrid>
            )}
        </>
      )}
    </>
  );
};

export default PrestationsForm;
