import React from "react";
import { Grid } from "@mui/material";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "./MemberBanner.css";
import CustomizedButton from "../../common/CustomizedButton";
import { useTranslation } from "react-i18next";

const ServiceText = styled.p`
  font-weight: 700 !important;
  font-size: 2.5rem !important;
  color: #ffffff;
`;

const TextButton = styled.span`
  text-decoration: none;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: bold;
`;

function MemberBanner() {
  const { t } = useTranslation();
  return (
    <Grid
      container
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      className="banner container">
      <Grid>
        <Grid item paddingLeft={"1em"}>
          <ServiceText className="bold">
            {t("home.banner.text1")}
            <br />
            {t("home.banner.text2")}
            <br />
            {t("home.banner.text3")}
          </ServiceText>
        </Grid>
        <Grid item container justifyContent={"flex-end"}>
          <Link to="/adhesion">
            <CustomizedButton width="12.4375em" height="2.1875em">
              <TextButton>{t("home.banner.becomeMember")}</TextButton>
            </CustomizedButton>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MemberBanner;
