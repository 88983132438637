import React from 'react';
import styled from 'styled-components';
import LogoMIA from '../common/logo/LogoMIA';
import { useTranslation } from "react-i18next";
import { useLocation, Link } from 'react-router-dom'
import CheckIcon from '@mui/icons-material/Check';
import './Sidebar.css';
import BurgerMenu from './burgerMenu/BurgerMenu';
import Button from '../common/form/Button';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/slices/userSlice';
import { apis } from '../../api';

const SidebarContainer = styled.div`
    display: flex;
    position:absolute;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    background-color: var(--primary-color);
    width: 18rem;
    z-index: 1;
    @media screen and (max-width: 1024px) {
        display: none;
        position: fixed;
        padding-top: 2em;
    }
`;

const SidebarSection = styled.div`
    margin: 0.7rem 0;
`;

const SidebarHeader = styled.div`
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--anthracite);
    text-transform: uppercase;
`;

const SelectItemIcon = styled(CheckIcon)`
    height: 1rem !important;
    margin-left: 0.5rem;
`;

const closeMobileMenu = () => {
    document.querySelectorAll(".open").forEach((elem) => elem.classList.remove("open"));
};

const SidebarItem = ({ path = "", children }) => {
    const location = useLocation();
    const isSelected = location.pathname === path;
    const classes = isSelected ? "sidebar-item active" : "sidebar-item";
    return (
        <div className={classes} >
            <Link to={path} onClick={closeMobileMenu}> {children} {isSelected ? <SelectItemIcon /> : null}</Link>
        </div >
    );
}

const SidebarHeaderSelectable = ({ path = "", children }) => {
    const location = useLocation();
    const isSelected = location.pathname === path;
    const classes = isSelected ? "sidebar-header active" : "sidebar-header";
    return (
        <SidebarHeader className={classes}>
            <Link to={path} onClick={closeMobileMenu}>{children} {isSelected ? <SelectItemIcon /> : null}</Link>
        </SidebarHeader>
    );
}

const DisconnectButton = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const disconnect = () => {
        dispatch(logout())
        apis.forEach((api) => dispatch(api.util.resetApiState()))
    }
    return (
        <Button onClick={disconnect} className="sidebar-btn">{t("member.sidebar.disconnect")}</Button>
    )
}


const Sidebar = () => {
    const { t } = useTranslation();

    return (
        <>
            <BurgerMenu />
            <SidebarContainer className='sidebar'>
                <LogoMIA />
                <DisconnectButton />
                {/* <SidebarSection>
                    <SidebarHeader>{t("member.sidebar.outMandatePrestation")}</SidebarHeader>
                    <SidebarItem path="/member/create-dossier">{t("member.sidebar.createCase")}</SidebarItem>
                    <SidebarItem>{t("member.sidebar.manageinProgressCases")}</SidebarItem>
                </SidebarSection> */}
                <SidebarSection>
                    <SidebarHeader>{t("member.sidebar.manageCases")}</SidebarHeader>
                    <SidebarItem path="/member/mandates/create">{t("member.sidebar.createMandate")}</SidebarItem>
                    <SidebarItem path="/member/mandates/inprogress">{t("member.sidebar.mandates")}</SidebarItem>
                    <SidebarItem path="/member/mandates/history">{t("member.sidebar.mandateHistory")}</SidebarItem>
                </SidebarSection>
                {/* <SidebarSection>
                    <SidebarHeader>{t("member.sidebar.orderPrestation")}</SidebarHeader>
                    <SidebarItem>{t("member.sidebar.placeOrder")}</SidebarItem>
                    <SidebarItem>{t("member.sidebar.createAnnouncement")}</SidebarItem>
                    <SidebarItem>{t("member.sidebar.orderHistory")}</SidebarItem>
                </SidebarSection> */}
                <SidebarSection>
                    <SidebarHeaderSelectable  path="/member/documents">{t("member.sidebar.docs")}</SidebarHeaderSelectable>
                </SidebarSection>
                <SidebarSection>
                    <SidebarHeaderSelectable path="/member/invoices">{t("member.sidebar.invoices")}</SidebarHeaderSelectable>
                </SidebarSection>
                <SidebarSection>
                    <SidebarHeaderSelectable path="/member/contact">{t("member.sidebar.contact")}</SidebarHeaderSelectable>
                </SidebarSection>
            </SidebarContainer>
        </>
    );
};

//onClick={(e) => { setTabSelected(tab.id); sidebar.current.classList.remove("open"); document.querySelector(".sidenav").classList.remove("open"); }}

export default Sidebar;