import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import defaultprepareHeaders from '../common/prepare-header';

export const propertyTypeApi = createApi({
    reducerPath: "propertyType",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/property-types", prepareHeaders: defaultprepareHeaders }),
    endpoints: (builder) => ({
      getPropertyTypes: builder.query({
        query: () => ({
          method: "GET"
        }),
      }),
    }),
  });
  
  export const { useGetPropertyTypesQuery } = propertyTypeApi;
  