import React from "react";
import './form.css';
import TextField from './TextField';
import { useTranslation } from 'react-i18next';


const PhoneField = (props) => {
  const { t } = useTranslation();
  return (
    <TextField
      inputProps={{
        pattern: "(\\+[0-9 ()]+)|((\\d\\d[.\\s]{0,1}){4}\\d\\d)",
        title: t("forms.validationMsg.phone"),
      }}
      type="tel"
      {...props}
    />
  );
};

export default PhoneField;
