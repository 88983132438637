import React, { useState } from 'react';
import { Alert, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextField from '../../components/common/form/TextField';
import Header from '../../components/common/header/Header';
import {
  strongPasswordRegex,
  useDefaultFieldProps,
} from '../../components/common/form/utils';
import './LoginPage.css';
import { Link, useParams } from 'react-router-dom';
import { useChangePwdMutation } from '../../api/auth/auth.api';
import QueriesErrors from '../../components/common/error/QueriesErrors';
import Button from '../../components/common/form/Button';

const ChangeForgotPasswordPage = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });

  const [changePwd, { isSuccess, ...sendEmailQuery }] = useChangePwdMutation();

  const { defaultFieldProps } = useDefaultFieldProps(formData, setFormData);

  const handleSubmit = (e) => {
    e.preventDefault();
    changePwd({
      token,
      body: {
        password: formData.password,
      },
    });
  };

  return (
    <>
      <Header title={t('login.forgotPwd.changePwd.title')}></Header>
      <Grid
        container
        justifyContent={'center'}
        alignContent={'center'}
        alignItems={'center'}
        minHeight={'100vh'}
      >
        <Grid className="loginpage" item md={8} paddingBottom={'20em'}>
          <form className="login-form" onSubmit={handleSubmit}>
            <Grid
              container
              flexDirection={'column'}
              justifyContent={'center'}
              alignContent={'center'}
              alignItems={'center'}
            >
              <Grid item>
                {isSuccess && (
                  <Alert severity="success">
                    {t('login.forgotPwd.changePwd.successMsg')}
                  </Alert>
                )}
                <QueriesErrors
                  queries={[sendEmailQuery]}
                  sx={{ maxWidth: '63em', whiteSpace: 'pre-line' }}
                />
              </Grid>
              <Grid
                item
                container
                flexDirection={'column'}
                justifyContent={'space-around'}
                minHeight={'10em'}
                md={6}
              >
                <Grid item>
                  <TextField
                    className="half-sized"
                    type="password"
                    {...defaultFieldProps('password')}
                    inputProps={{
                      pattern: strongPasswordRegex,
                      title: t('forms.validationMsg.password'),
                    }}
                    label={t('login.forgotPwd.changePwd.passwordLabel')}
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    className="half-sized"
                    type="password"
                    {...defaultFieldProps('confirmPassword')}
                    label={t('login.forgotPwd.changePwd.confirmPasswordLabel')}
                    required
                    onKeyUp={(e) => {
                      if (e.target.value !== formData.password) {
                        e.target.setCustomValidity(
                          t('forms.validationMsg.confirmPassword'),
                        );
                      } else {
                        e.target.setCustomValidity('');
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction={'row-reverse'}
                marginTop={'5em'}
                gap={'2em'}
                md={6}
              >
                <Grid item md={12} sm={12} lg={'auto'}>
                  <Button type="submit" disabled={isSuccess}>
                    {t('login.forgotPwd.changePwd.submit')}
                  </Button>
                </Grid>
                <Grid item md={12} sm={12} lg={'auto'}>
                  <Link to={'/login'}>
                    <Button className="secondary">
                      {t('login.forgotPwd.changePwd.loginPageLink')}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default ChangeForgotPasswordPage;
