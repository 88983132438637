import React, { useEffect, useState } from 'react';
import { useCloseMandateMutation } from '../../../api/mandate/mandate.api';
import FormGrid from '../../common/form/FormGrid';
import RadioField from '../../common/form/RadioFields';
import { useTranslation } from 'react-i18next';
import { useDefaultFieldProps } from '../../common/form/utils';
import Button from '../../common/form/Button';
import QueriesErrors from '../../common/error/QueriesErrors';
import { CircularProgress } from '@mui/material';

const MandateClosureForm = ({ mandateId, onSuccess, ...props }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'member.mandate.closure',
  });

  const [closeMandate, closeMandateQuery] = useCloseMandateMutation();

  useEffect(() => {
    if(closeMandateQuery.isSuccess && onSuccess) {
        onSuccess();
    }
  }, [closeMandateQuery.isSuccess])

  const [formData, setFormData] = useState({
    status: null,
  });

  const { defaultFieldProps } = useDefaultFieldProps(formData, setFormData);

  return (
    <div className="mandate-closure-form">
      <FormGrid
        onSubmit={(e) => {
          e.preventDefault();
          closeMandate({ mandateId, body: formData });
          return false;
        }}
        {...props}
      >
        <h3>{t('title')}</h3>
        <RadioField
          label={t('statusRadio.label')}
          {...defaultFieldProps('status')}
          row
          required
          options={[
            { value: 'VENDU', label: t('statusRadio.valueLabels.VENDU') },
            { value: 'PERDU', label: t('statusRadio.valueLabels.PERDU') },
          ]}
        />
        <div className="actions">
          <Button type="submit">{t('submit')}</Button>
          {closeMandateQuery.isLoading && <CircularProgress />}
          <QueriesErrors queries={[closeMandateQuery]} />
        </div>
      </FormGrid>
    </div>
  );
};

export default MandateClosureForm;
