import React from 'react';
import Sidebar from '../sidebar/Sidebar';
import styled from "styled-components";
import Bubble from '../bubble/Bubble';

const RootMemberLayout = styled.div`
  background-color: var(--primary-color);
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18rem;
  padding: 2rem 2rem 18rem 2rem;
  background-color: white;
  z-index: 0;
  position: relative;
  min-height: 100vh;
  @media screen and (max-width: 1024px) {
    margin-left: 0rem;
  }
`;

const PageTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: var(--anthracite);
  margin: 1rem 0;
  text-align: center;
`;

const PageDescription = styled.p`
  font-size: 1.2rem;
  color: var(--anthracite);
  margin-bottom: 2rem;
`;

const MemberLayout = ({ title, description = "", children }) => (
  <RootMemberLayout>
    <Sidebar />
    <PageContainer>
      <PageTitle>{title}</PageTitle>
      <PageDescription>
        {description}
      </PageDescription>
      {children}
      <Bubble />
    </PageContainer>
  </RootMemberLayout>
);

export default MemberLayout;