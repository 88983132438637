import React from 'react';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import IllustrationMandatImg from "../../assets/images/IllustrationMandat.jpg";
import DownloadIcon from "@mui/icons-material/Download";
import "./MandateCard.css";
import IconButton from "@mui/material/IconButton";
import { exportDocument } from "./utils";
import { useSelector } from 'react-redux';
import { selectUserData } from '../../redux/slices/userSlice';
import { SkeletonCardMedia } from '../common/Common';
import CardActions from '@mui/material/CardActions';

const StatutStrip = styled.div`
  height: 2em;
  background-color: var(--dark-grey);
  text-align: center;
  line-height: 2em;
  color: var(--white);
`;

const MandateCard = ({ mandate, feesDecimalNumber = 0 }) => {
  const { t } = useTranslation("", { keyPrefix: 'member.mandate.view' });
  const member = useSelector(selectUserData)

  const exportMandate = () => {
    exportDocument(mandate, member);
  };

  return (
    <Card className="mandate-card">
      <CardActionArea>
        <Link to={`/member/mandates/${mandate.id}/edit`} key={mandate.id}>
          <SkeletonCardMedia
            image={IllustrationMandatImg}
          />
        </Link>
        {mandate.status && (
          <StatutStrip>
            {t(`status.${mandate.status}`)}
          </StatutStrip>
        )}
        <CardContent>
          <div className="actions">
            <IconButton aria-label="download" onClick={exportMandate}>
              <DownloadIcon />
            </IconButton>
          </div>
          <h4 className="title">
            {!mandate.content.internalMandateNumber
              ? mandate.content.mandateNumber
              : mandate.content.internalMandateNumber} - {mandate.content.city} ({mandate.content.postalCode})
          </h4>
          {!!mandate.content.internalMandateNumber && (
            <div className="sub-title">{mandate.content.mandateNumber}</div>
          )}
          <p className='amount'>
            {`${t("feesInclVAT")}: ${(
              mandate.content.estimatedPrice *
              (1 + mandate.content.mandateRate)
            ).toFixed(feesDecimalNumber)} €`}
            :
          </p>
        </CardContent>
        <CardActions>
            <Link to={"/member/invoices/" + mandate.id} style={{ textDecoration: "underline" }} className='invoiceLink'>
              {t(`consulterFacture`)}
            </Link>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

export default MandateCard;
