import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import defaultprepareHeaders from "../common/prepare-header";

export const documentsApi = createApi({
  reducerPath: "documentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/documents",
    prepareHeaders: defaultprepareHeaders,
  }),
  endpoints: (builder) => ({
    getDocuments: builder.query({
      query: () => "",
      providesTags: ["list"],
    }),
  }),
});

export const { useGetDocumentsQuery } = documentsApi;
