import React from "react";
import { Grid } from "@mui/material";
import "./Bottom.css";
import { Link } from "react-router-dom";

function Bottom() {
  return (
    <Grid container  justifyContent={"flex-end"} className="bottom">
      <Grid item container className="background" justifyContent={"flex-end"} alignContent={"flex-end"} md={10}>
        <Grid item container className="container-link" justifyContent={"space-around"} md={4} >
          <Link  className="link bold" to={"https://marcheimmobilierdesavocats.com/mentions-legales.php"}>Mention légales</Link>
      </Grid>
      </Grid>
    </Grid>
  );
}

export default Bottom;
