import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import "./FreeBenefitsItem.css";

const FreeBenefits = ({ description }) => {
  return (
    <div className="free-benefits-item">
       <div className="check">
        <CheckIcon />
      </div>
      <div className="description">{description}</div>
    </div>
  );
}

export default FreeBenefits;
