import React from 'react';
import { Grid } from "@mui/material";
import LogoMIA from "../logo/LogoMIA";
import "./Header.css";

const Header = ({ title }) => {
  return (
    <Grid container className="header">
      <Grid
        item
        container
        justifyContent={"center"}
        md={2}
        sm={12}
        xs={12}>
        <LogoMIA />
      </Grid>
      <Grid
        item
        container
        className="content"
        justifyContent={"center"}
        alignContent={"center"}
        md={8}
        sm={12}
        xs={12}>
        <h1>{title}</h1>
      </Grid>
    </Grid>
  );
};

export default Header;
