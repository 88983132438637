import React from "react";
import Contact from "../../components/common/contact/Contact";
import "./ContactMemberPage.css";

const ContactMemberPage = () => {
    return (
        <div className="contact-member">
            <div className="contact-details">
                <Contact shouldDisplayPhone={true} />
            </div>
        </div>
    );
};

export default ContactMemberPage;
