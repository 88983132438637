import React, { useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "./form.css";
import TextField from "./TextField";

const DateField = ({ value, onChange = (newDate) => {}, inputFormat, required, name ='', ...props }) => {
  let [date, setDate] = useState(new Date());
  return (
    <DesktopDatePicker
      label="Date desktop"
      inputFormat={inputFormat}
      value={value === undefined ? date : value}
      onChange={(newDate) => {
        setDate(newDate);
        if(onChange) onChange(newDate);
      }}
      {...props}
      renderInput={(params) => <TextField name={name} {...{required}} {...params} />}
    />
  );
};

export default DateField;
