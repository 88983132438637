import React from 'react';
import Button from '../form/Button';

const SystemPayForm = ({ body = {}, sendLabel, disabled = false, ...formProps }) => {
  return (
    <form
      className="payment-hidden-form"
      method="POST"
      action="https://paiement.systempay.fr/vads-payment/"
      {...formProps}
    >
      {Object.entries(body).map(([name, value]) => (
        <input type="hidden" name={name} value={value} key={name}/>
      ))}
      <div className="actions">
        <Button type="submit" disabled={disabled}>
          {sendLabel}
        </Button>
      </div>
    </form>
  );
};

export default SystemPayForm;
