import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import userReducer from './slices/userSlice';
import { authApi } from '../api/auth/auth.api';
import { userApi } from '../api/user/user.api';
import { adhesionApi } from './../api/adhesion/adhesion.api';
import { barApi } from './../api/bar/bar.api';
import { mandateApi } from './../api/mandate/mandate.api';
import { propertyTypeApi } from './../api/property-type/property-type.api';
import { prestationApi } from '../api/prestation/prestation.api';
import { invoiceApi } from "../api/invoice/invoice.api";
import { documentsApi } from "../api/documents/documents.api";

const userPersistConfig = {
  key: 'user',
  storage: storage,
};

export const store = configureStore({
  reducer: {
    user: persistReducer(userPersistConfig, userReducer),
    [authApi.reducerPath]: authApi.reducer,
    [adhesionApi.reducerPath]: adhesionApi.reducer,
    [barApi.reducerPath]: barApi.reducer,
    [mandateApi.reducerPath]: mandateApi.reducer,
    [propertyTypeApi.reducerPath]: propertyTypeApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [prestationApi.reducerPath]: prestationApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [documentsApi.reducerPath]: documentsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authApi.middleware,
      adhesionApi.middleware,
      barApi.middleware,
      mandateApi.middleware,
      propertyTypeApi.middleware,
      userApi.middleware,
      prestationApi.middleware,
      invoiceApi.middleware,
      documentsApi.middleware,
    ]),
});
export const persistor = persistStore(store);
setupListeners(store.dispatch);
