import React from "react";
import styled from "styled-components";
import { ReactComponent as LogoSvg } from "../../../assets/images/logo.svg";
import { Link } from "react-router-dom";

const RootLogo = styled.div`
    text-align: center;
`;

const Logo = styled(LogoSvg)`
  width: 9rem;
  padding: 1rem;
  color: var(--dark-red);
`;

const LogoMIA = ({ logoStyle = {} }) => {
  return (
    <RootLogo className="logo-mia">
      <Link to="/">
        <Logo style={logoStyle} />
      </Link>
    </RootLogo>
  );
}

export default LogoMIA;
