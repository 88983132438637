import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/common/form/Button';
import { Link, useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import styled from 'styled-components';

const LinksLayout = styled.div`
  margin-top: 5em;
  display: flex;
  justify-content: center;
  gap: 2em;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  & button {
    min-width: 20em;
  }
`;

const ContactLayout = styled.div`
  width: fit-content;
  margin: auto;
  margin-top: 5em;
  & li {
    padding: 0.3em;
    text-align: start;
  }
  & li .contact-label {
    min-width: 10em;
    padding-right: 0.3em;
  }
`;

const MandateOrderConfirmationPage = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'member.mandate.prestation',
  });
  const { status, mandateId } = useParams();

  return (
    <Box sx={{ textAlign: 'center' }}>
      <h1>{t(`confirmation.${status}.title`)}</h1>
      <p>{t(`confirmation.${status}.message`)}</p>
      {['INTERNAL_ERROR'].includes(status) && (
        <ContactLayout>
          <h4>{t(`confirmation.INTERNAL_ERROR.contact.label`)}</h4>
          <ul>
            <li>
              <span className="contact-label">
                {t(`confirmation.INTERNAL_ERROR.contact.email.label`)}
              </span>
              <a
                href={`mailto:${t(
                  `confirmation.INTERNAL_ERROR.contact.email.value`,
                )}`}
              >
                {t(`confirmation.INTERNAL_ERROR.contact.email.value`)}
              </a>
            </li>
            <li>
              <span className="contact-label">
                {t(`confirmation.INTERNAL_ERROR.contact.phone.label`)}
              </span>
              <a
                href={`tel:${t(
                  `confirmation.INTERNAL_ERROR.contact.phone.value`,
                )}`}
              >
                {t(`confirmation.INTERNAL_ERROR.contact.phone.value`)}
              </a>
            </li>
          </ul>
        </ContactLayout>
      )}
      <LinksLayout>
        {['OK'].includes(status) && (
          <Link to={`/member/mandates/${mandateId}/edit`}>
            <Button>{t('confirmation.editLink')}</Button>
          </Link>
        )}
        {['KO', 'CANCEL'].includes(status) && (
          <Link to={`/member/mandates/${mandateId}/order/recap`}>
            <Button>{t('confirmation.tryAgainLink')}</Button>
          </Link>
        )}
        <Link to={'/member'}>
          <Button className="secondary">
            {t('confirmation.homeLink')}
          </Button>
        </Link>
      </LinksLayout>
    </Box>
  );
};

export default MandateOrderConfirmationPage;
