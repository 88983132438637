const defaultprepareHeaders = (headers, { getState }) => {
    const token = getState().user?.value?.token
    
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    return headers
  }

  export default defaultprepareHeaders;