import React from "react";
import { Grid } from "@mui/material";
import "./Partner.css";
import {
  leboncoin,
  partnerGroup,
  partnerSeloger,
} from "../../../assets/images";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { InvisibleBlockForGrid, SectionTitle } from "../../common/Common";

const PartnerLogo = styled.img`
  height: 3.2175em;
  padding: 1em 3em;
`;
function Partner() {
  const { t } = useTranslation();
  return (
    <Grid container flexDirection={"column"} justifyContent={"space-around"}>
      <Grid
        container
        item
        flexDirection={"row"}
        justifyContent={"space-around"}
        marginTop={"3em"}>
        <SectionTitle>{t("home.partner.title")}</SectionTitle>
        <InvisibleBlockForGrid />
      </Grid>

      <Grid
        container
        item
        flexDirection={"row"}
        justifyContent={"space-around"}
        alignItems={"center"}
        alignContent={"center"}
        className="partner container">
        <PartnerLogo src={partnerSeloger} />
        <PartnerLogo src={leboncoin} />
        <PartnerLogo src={partnerGroup} />
      </Grid>
    </Grid>
  );
}

export default Partner;
