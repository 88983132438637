import React, { useState, useEffect } from 'react';
import Button from '../../common/form/Button';
import SelectField from '../../common/form/SelectField';
import { useTranslation } from 'react-i18next';
import { useDefaultFieldProps } from '../../common/form/utils';
import LinearProgress from '@mui/material/LinearProgress';
import FormGrid from './../../common/form/FormGrid';
import {
  usePreparePropertyDetailsFormQuery,
  useUpdatePropertyDetailsMutation,
} from '../../../api/mandate/mandate.api';
import { useGetPropertyTypesQuery } from '../../../api/property-type/property-type.api';
import { propertyUsages } from '../../../api/mandate/mandate.dto';
import QueriesErrors from './../../common/error/QueriesErrors';
import propertyDetailFields from '../json/PropertyDetailFields.json';
import FactoryField from './factoryField/FactoryField';
import QueryFeedBack from '../../query/QueryFeedback';

const defaultFormData = {
  constructionDate: new Date(),
  constructionPermitDate: new Date(),
};

const propertTypesMapping = {
  1: 'appartement',
  6: 'maison',
  5: 'villa',
  10: 'propriete_exception',
  12: 'fond_commerce',
  7: 'terrain',
  3: 'local_commercial',
  2: 'garage',
  4: 'bureau',
  8: 'local_industriel',
  11: 'immeuble',
};

const PropertyDetailsForm = ({ mandateId, isEditionMode = false }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'member.mandate.edition',
  });

  // ---- QUERIES ---- //
  const {
    data: formInitData,
    isLoading: isFormInitQueryLoading,
    isSuccess: isFormInitQuerySuccess,
    ...forminitQuery
  } = usePreparePropertyDetailsFormQuery(mandateId);
  const {
    data: propertyTypes,
    isSuccess: isPropertyTypesQuerySuccess,
    isLoading: isPropertyTypesQueryLoading,
    ...propertyTypesQuery
  } = useGetPropertyTypesQuery({});
  const isQueriesLoading =
    isPropertyTypesQueryLoading || isFormInitQueryLoading;
  const isQueriesSuccess =
    isFormInitQuerySuccess || isPropertyTypesQuerySuccess;

  // ---- MUTATORS ---- //
  const [updatePropertyDetails, editionQuery] =
    useUpdatePropertyDetailsMutation();

  // ---- FORM DATA & LOGIC ---- //
  const [typologies, setTypologies] = useState(undefined);
  const [formData, setFormData] = useState(defaultFormData);
  const [successMessage, setSuccessMessage] = useState(undefined);

  const getAvailableTypologies = (data) => {
    return propertyTypes?.find((type) => type.id === data?.propertyTypeId)
      ?.typologies;
  };
  const getValidTypology = (data, availableTypo) => {
    if (!availableTypo) return '';
    const validTypo = availableTypo.find((typo) => typo.id === data.typologyId);
    return !!validTypo ? validTypo.id : '';
  };

  useEffect(() => {
    const availableTypo = getAvailableTypologies(formInitData);
    const typologyId = getValidTypology(formInitData, availableTypo);

    setTypologies(availableTypo);
    setFormData({
      ...defaultFormData,
      ...formInitData,
      typologyId,
    });
  }, [formInitData, propertyTypes]);

  useEffect(() => {
    if (editionQuery.isLoading) {
      setSuccessMessage(
        formInitData?.isInCreationMode
          ? t('successMsg.detailsSave')
          : undefined,
      );
    }
  }, [editionQuery.isLoading]);

  const { defaultFieldProps } = useDefaultFieldProps(formData, setFormData);

  return (
    <>
      <QueriesErrors queries={[forminitQuery, propertyTypesQuery]} />
      {(isQueriesLoading && <LinearProgress />) || (
        <>
          <FormGrid
            className="property-details-edition-page"
            onSubmit={(e) => {
              e.preventDefault();
              updatePropertyDetails({ mandateId, body: formData });
              return false;
            }}
          >
            <SelectField
              {...defaultFieldProps('propertyTypeId')}
              label={t('propertyTypeLabel')}
              options={(propertyTypes ?? []).map((s) => ({
                label: s.label,
                value: s.id,
              }))}
              disabled
              required
            />

            {(
              propertyDetailFields[
                propertTypesMapping[formData.propertyTypeId]
              ] ?? []
            ).map((field) => {
              return (
                <FactoryField
                  field={field}
                  formData={formData}
                  setFormData={setFormData}
                  t={t}
                  key={field.name}
                  selectOptionsMap={{
                    typologies: (typologies ?? []).map(({ label, id }) => ({
                      value: id,
                      label,
                    })),
                    propertyUsages,
                  }}
                />
              );
            })}
            <div className="actions">
              <Button type="submit" disabled={!isQueriesSuccess}>
                {t('submit.modify')}
              </Button>
              <QueryFeedBack
                query={editionQuery}
                type="save"
                successMsg={successMessage}
              />
            </div>
          </FormGrid>
        </>
      )}
    </>
  );
};

export default PropertyDetailsForm;
