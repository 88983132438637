import React from "react";
import { Card, CardContent, Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DescriptionBox, SeparatorBox, TitleBox } from "../../common/Common";
import styled from "styled-components";
import "./RealEstateLawyers.css";

const Description = styled.p`
  font-size: 2.5em;
`;

function RealEstateLawyers() {
  const matches = useMediaQuery("(min-width:900px)");
  const { t } = useTranslation();
  return (
    <Grid className="real-state-lawyers" container flexDirection={"row"} id="about">
      <Grid
        className="left-tab"
        item
        container
        alignContent={"space-around"}
        justifyContent={"center"}
        md={5}
        sm={12}
        xs={12}
      >
        <Grid item md={8} sm={12} xs={12} className="container" >
          <p className="bold">
            {t("home.realestatelawyers.leftBlock.title1")} <br />
            {t("home.realestatelawyers.leftBlock.title2")}
          </p>
          <div className="description">
            <p>{t("home.realestatelawyers.leftBlock.text1")} </p>
            <p>{t("home.realestatelawyers.leftBlock.text2")} </p>
            <p>{t("home.realestatelawyers.leftBlock.text3")}</p>
          </div>
        </Grid>
        <Grid item md={7} sm={12} xs={12}>
          <div className="box">
            <Card className="box-card">
              <CardContent>
                <TitleBox>{t("home.realestatelawyers.leftBlock.box.title")}</TitleBox>
                <SeparatorBox />
                <DescriptionBox>
                  {t("home.realestatelawyers.leftBlock.box.description")}
                </DescriptionBox>
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
      <Grid className="right-tab" item container md={7} sm={12} xs={12}>
        {matches && (
          <Grid
            item
            container
            className="container-source-img"
            justifyContent={"flex-end"}
            md={12}
            sm={12}
            xs={12}>
            <div className="source-img"></div>
          </Grid>
        )}
        <div className="box">
          <Card className="box-card">
            <CardContent>
              <TitleBox>{t("home.realestatelawyers.rightBlock.box.title")}</TitleBox>
              <SeparatorBox />
              <DescriptionBox>
                {t("home.realestatelawyers.rightBlock.box.description1")}
              </DescriptionBox>
              <DescriptionBox>
                {t("home.realestatelawyers.rightBlock.box.description2")}<br />
                {t("home.realestatelawyers.rightBlock.box.description2-a")}<br />
                {t("home.realestatelawyers.rightBlock.box.description2-b")}
              </DescriptionBox>
              <DescriptionBox>
                {t("home.realestatelawyers.rightBlock.box.description3")}
              </DescriptionBox>
            </CardContent>
          </Card>
        </div>
        <Grid
          item
          container
          md={12}
          sm={12}
          xs={12}
          marginTop={matches ? "20em" : "0"}
          alignContent={"flex-start"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          justifyItems={"flex-start"}>
          <Description className="citation bold container">
            {t("home.realestatelawyers.rightBlock.bottomText1")} <br />
            {t("home.realestatelawyers.rightBlock.bottomText2")}
          </Description>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RealEstateLawyers;
