import React from "react";
import "./form.css";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

const SelectField = ({
  name,
  label,
  className = "",
  options = [],
  value = "",
  ...props
}) => {
  const fieldId = "field-" + name;
  return (
    <div className={"field select-field " + className} {...props}>
      {!!label && (
        <Tooltip title={label} placement="top-start">
          <InputLabel id={fieldId + "-label"}>{label}</InputLabel>
        </Tooltip>
      )}
      <Select
        labelId={fieldId + "-label"}
        fullWidth
        size="small"
        name={name}
        value={value ?? ""}
        {...props}
      >
        <MenuItem value="" disabled={!!props.required} key="none">{'------ Selectionner ------'}</MenuItem>
        {options.map(({ value: optVal, label }) => (
          <MenuItem value={optVal} key={optVal}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectField;
