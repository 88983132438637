import React from "react";
import AppBar from "@mui/material/AppBar";
// @ts-ignore
import "./Header.css";
import { Grid } from "@mui/material";
import NavBar from "./NavBar/NavBar";
import MyAccount from "./MyAccount/MyAccount";
import LogoMIA from './../../common/logo/LogoMIA';

function Header() {
  return (
    <AppBar className="header" color={"transparent"} position={"relative"}>
      <Grid
        container
        minHeight={"12em"}
        alignContent={"center"}
        alignItems={"center"}>
        <Grid container item className="logo-container" md={3} sm={12} xs={12}>
          <LogoMIA logoStyle={{ padding: 0 }} />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <NavBar />
        </Grid>
        <Grid item md={3} sm={12} xs={12} height={"4em"}>
          <MyAccount />
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default Header;
