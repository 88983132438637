import { useTranslation } from "react-i18next";
import { steps } from "./../layout/AdhesionFormLayout";
import "./AdhesionHeader.css";
import LogoMIA from './../../common/logo/LogoMIA';

const AdhesionHeader = ({ step }) => {
  const { t } = useTranslation();

  return (
    <div className="mia-adhesion-header">
      <div className="logo">
        <LogoMIA/>
      </div>

      <div className="content">
        <h1>{t("adhesion.header.title")}</h1>
        <div className="tabs">
          {Object.values(steps).map((currentStep) => (
            <div className={"tab" + (step === currentStep ? " selected" : "")} key={currentStep}>
              {t("adhesion.header.sections." + currentStep)}
            </div>
          ))}
        </div>
      </div>

      <div className="side-action"></div>
    </div>
  );
};

export default AdhesionHeader;
