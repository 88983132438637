import React, { createContext } from "react";
import "./HomePage.css";
import Header from "../../components/home/header/Header";
import OurServices from "../../components/home/service/OurServices";
import MemberBanner from "../../components/home/memberbanner/MemberBanner";
import Partner from "../../components/home/partner/Partner";
import OurServicesWork from "../../components/home/ourservicework/OurServicesWork";
import BannerService from "../../components/home/bannerService/BannerService";
import RealEstateLawyers from "../../components/home/realestatelawyers/RealEstateLawyers";
import ContactHomepage from "../../components/home/contact/ContactHomepage";
import Bottom from "../../components/home/bottom/Bottom";

export const Section = createContext("service");
function HomePage() {
    return (
        <Section.Provider value="service">
            <Header />
            <MemberBanner />
            <OurServices />
            <Partner />
            <OurServicesWork />
            <BannerService />
            <RealEstateLawyers />
            <ContactHomepage />
            <Bottom />
        </Section.Provider>
    );
}

export default HomePage;
