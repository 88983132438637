import React, { useState } from "react";
import styled from "styled-components";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
// @ts-ignore
import IllustrationInvoiceImg from "../../assets/images/illustrationInvoice.jpg";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import "./InvoiceCard.css";
import { format } from "date-fns";
import { SkeletonCardMedia } from "../common/Common";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/slices/userSlice";
import DownloadSnackbar from '../common/download/DownloadSnackbar';

const Price = styled.div`
  position: absolute;
  bottom: 0.8em;
  right: 0.6em;
`;

const InvoiceCard = ({ invoice }) => {
  const member = useSelector(selectUserData);

  const [isLoading, setIsLoading] = useState(false)

  const downloadInvoice = async () => {

    const body = {
      id: invoice.id,
      libelle: invoice.libelle,
    };
    try {
      setIsLoading(true);
      const response = await fetch(`/api/invoices/pdf`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${member.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      setIsLoading(false);

      if (response.ok) {
        const fileBlob = await response.blob();
        const fileUrl = URL.createObjectURL(fileBlob);
        const downloadLink = document.createElement("a");

        downloadLink.href = fileUrl;
        downloadLink.download = invoice.numeroFacture;
        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
      } else {
        console.error("Erreur lors du téléchargement :", response.statusText);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Erreur lors du téléchargement :", error);
    }
  };

  const calculateAmount = () => {
    let amount = 0;
    return invoice?.ligneFactureCommandes
      ? invoice?.ligneFactureCommandes.reduce((accumulator, ligne) => {
          return (
            accumulator +
            ligne.prixUnitaireHt * ligne.quantite * (1 + invoice.tauxTva)
          );
        }, amount).toFixed(2)
      : invoice.montantHt * (1 + invoice.tauxTva);
  };

  return (
    <Card className="invoice-card">
      <CardActionArea onClick={downloadInvoice}>
        <SkeletonCardMedia image={IllustrationInvoiceImg} />
        <CardContent>
          <div className="actions">
            <IconButton aria-label="download">
              <DownloadIcon />
            </IconButton>
          </div>
          <h4 className="title">{invoice.numeroFacture}</h4>
          <div>
            {format(
              new Date(
                invoice.dateCotisation ? invoice.dateCotisation : invoice.date
              ),
              "dd/MM/yyyy"
            )}
          </div>
          <Price>{calculateAmount()} €</Price>
        </CardContent>
      </CardActionArea>
      <DownloadSnackbar isLoading={isLoading} />
    </Card>
  );
};

export default InvoiceCard;
