import React from "react";
import { Grid } from "@mui/material";
import styled from "styled-components";
import "./BannerService.css";
import { useTranslation } from "react-i18next";

const ServiceText = styled.p`
  font-weight: 700 !important;
  font-size: 2.5rem !important;
  line-height: 1.5em !important;
  color: #ffffff;
  margin: 6rem 0;
`;

function BannerService() {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent={"center"} alignItems={"center"} className="banner-service" height={"30em"}>
      <div className="description container">
        <ServiceText className="bold">
          {t("home.bannerServices.text1")}
          <br />
          {t("home.bannerServices.text2")}
          <br />
          {t("home.bannerServices.text3")}
          <br />
          {t("home.bannerServices.text4")}
        </ServiceText>
      </div>
    </Grid>
  );
}

export default BannerService;
