import React from "react";
import "./BurgerMenu.css"

class BurgerMenu extends React.Component {

    render() {
        return (
            <button className="sidenav" aria-expanded="false" onClick={this.handleClick.bind(this)}>
                <div className="icon-bar"></div>
                <div className="icon-bar"></div>
                <div className="icon-bar"></div>
            </button>
        );
    }

    handleClick(event) {
        const sidebar = document.querySelector(".sidebar");
        const sidenav = document.querySelector(".sidenav");
        if (this.isOpen(sidebar)) {
            this.close(sidebar, sidenav);
        } else {
            this.open(sidebar, sidenav);
        }
        event.stopPropagation();
        return false;
    }

    close(sidebar, sidenav) { sidebar.classList.remove("open"); sidenav.classList.remove("open")}
    open(sidebar, sidenav) { sidebar.classList.add("open"); sidenav.classList.add("open")}
    isOpen(sidebar, sidenav) { return sidebar.classList.contains("open");}
}

export default BurgerMenu;
