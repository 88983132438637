import React from 'react';
import SubNaturalOwnerForm from './SubNaturalOwnerForm';
import RadioField from '../../common/form/RadioFields';
import SubLegalOwnerForm from './SubLegalOwnerForm';
import { useDefaultFieldProps } from '../../common/form/utils';
import './SubOwnerForm.css';
import { useTranslation } from 'react-i18next';

const SubOwnerForm = ({
  value: owner = {
    isOwnerNatural: true,
    naturalOwners: [],
    legalOwner: {},
  }.owner,
  setValue: setOwners,
}) => {
  const { t } = useTranslation();
  const { defaultFieldProps, defaultSubLstFieldProps, defaultSubFormProps } =
    useDefaultFieldProps(owner, setOwners);
    
  return (
    <>
      <h3>{t('member.mandate.edition.owners.label')}</h3>
      <RadioField
        label={t('member.mandate.edition.owners.typeLabel')}
        {...defaultFieldProps('isOwnerNatural', { parseBoolean: true })}
        row
        options={[
          { value: true, label: t('member.mandate.edition.owners.naturalLabel') },
          { value: false, label: t('member.mandate.edition.owners.legalLabel') },
        ]}
      />
      {owner.isOwnerNatural ? (
        <SubNaturalOwnerForm {...defaultSubLstFieldProps('naturalOwners')} />
      ) : (
        <SubLegalOwnerForm {...defaultSubFormProps('legalOwner')} />
      )}
    </>
  );
};

export default SubOwnerForm;
