import React, { useEffect, useState } from 'react';
import { status } from '../../api/adhesion/adhesion.dto';
import { useTranslation } from 'react-i18next';
import TextField, { postalCodeValidationProps } from '../../components/common/form/TextField';
import SelectField from '../../components/common/form/SelectField';
import DateField from '../../components/common/form/DateField';
import Button from '../../components/common/form/Button';
import PhoneField from '../../components/common/form/PhoneField';
import AdhesionFormLayout, {
  steps,
} from '../../components/adhesion-form/layout/AdhesionFormLayout';
import { useGetBarsQuery } from '../../api/bar/bar.api';
import { LinearProgress } from '@mui/material';
import { useRegisterPrePaymentAdhesionMutation } from '../../api/adhesion/adhesion.api';
import {
  useDefaultFieldProps,
  strongPasswordRegex,
} from '../../components/common/form/utils';
import { useNavigate } from 'react-router-dom';
import FormGrid from './../../components/common/form/FormGrid';
import QueryFeedBack from '../../components/query/QueryFeedback';

const AdhesionFormPersonalDataPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Server query
  const {
    data: bars,
    isSuccess: isBarsSuccess,
    isLoading: isBarsLoading,
  } = useGetBarsQuery(undefined);

  const getBarsOptions = () => {
    /**  @type {{ label: string; value: number; }[]} */
    const options = (isBarsSuccess ? bars : []).map((s) => ({
      label: s.name,
      value: s.id,
    }));
    options.sort((a, b) => a.label.localeCompare(b.label));
    return options;
  };

  const [
    registerAdhesion,
    submitQuery,
  ] = useRegisterPrePaymentAdhesionMutation();

  // form config
  const [adhesionForm, setAdhesionForm] = useState({
    name: '',
    firstName: '',
    office: '',
    status: '',
    speciality: '',
    relatedBarId: -1,
    address: '',
    postalCode: '',
    city: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    oathDate: new Date(),
  });

  const { defaultFieldProps } = useDefaultFieldProps(
    adhesionForm,
    setAdhesionForm,
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const result = { ...adhesionForm };
    delete result.confirmPassword;
    registerAdhesion(result);
    return false;
  };

  useEffect(() => {
    if (submitQuery.isSuccess) {
      navigate(`${adhesionForm.email}/acceptance-step`);
    }
  }, [submitQuery.isSuccess]);

  return (
    <AdhesionFormLayout step={steps.personalData}>
      {(isBarsLoading && <LinearProgress />) || (
        <FormGrid className="adhesion-page" onSubmit={handleSubmit}>
          <TextField
            className="half-sized"
            {...defaultFieldProps('name')}
            label={t('adhesion.personalData.nameLabel')}
            required
          />
          <TextField
            className="half-sized"
            {...defaultFieldProps('firstName')}
            label={t('adhesion.personalData.firstNameLabel')}
            required
          />
          <TextField
            {...defaultFieldProps('office')}
            label={t('adhesion.personalData.officeLabel')}
            required
          />
          <SelectField
            className="half-sized"
            label={t('adhesion.personalData.statusSelect.label')}
            options={status.map((s) => ({
              label: t('adhesion.personalData.statusSelect.valueLabels.' + s),
              value: s,
            }))}
            {...defaultFieldProps('status')}
            required
          />
          <TextField
            className="half-sized"
            {...defaultFieldProps('speciality')}
            label={t('adhesion.personalData.specialityLabel')}
          />
          <DateField
            className="half-sized"
            {...defaultFieldProps('oathDate')}
            inputFormat="dd/MM/yyyy"
            label={t('adhesion.personalData.oathDateLabel')}
            required
          />
          <SelectField
            className="half-sized"
            label={t('adhesion.personalData.relatedBarLabel')}
            options={getBarsOptions()}
            {...defaultFieldProps('relatedBarId')}
            required
          />
          <TextField
            {...defaultFieldProps('address')}
            label={t('adhesion.personalData.addressLabel')}
            required
          />
          <TextField
            className="half-sized"
            {...defaultFieldProps('postalCode')}
            {...postalCodeValidationProps(t('forms.validationMsg.postalCode'))}
            label={t('adhesion.personalData.postalCodeLabel')}
            required
          />
          <TextField
            className="half-sized"
            {...defaultFieldProps('city')}
            label={t('adhesion.personalData.cityLabel')}
            required
          />
          <TextField
            type="email"
            {...defaultFieldProps('email')}
            label={t('adhesion.personalData.emailLabel')}
            required
          />
          <PhoneField
            className="half-sized"
            {...defaultFieldProps('phone')}
            label={t('adhesion.personalData.phoneLabel')}
            required
          />
          <PhoneField
            className="half-sized"
            {...defaultFieldProps('officePhone')}
            label={t('adhesion.personalData.officePhoneLabel')}
          />
          <TextField
            className="half-sized"
            type="password"
            {...defaultFieldProps('password')}
            inputProps={{
              pattern: strongPasswordRegex,
              title: t('forms.validationMsg.password'),
            }}
            label={t('adhesion.personalData.passwordLabel')}
            required
          />
          <TextField
            className="half-sized"
            type="password"
            {...defaultFieldProps('confirmPassword')}
            label={t('adhesion.personalData.confirmPasswordLabel')}
            required
            onKeyUp={(e) => {
              if (e.target.value !== adhesionForm.password) {
                e.target.setCustomValidity(
                  t('forms.validationMsg.confirmPassword'),
                );
              } else {
                e.target.setCustomValidity('');
              }
            }}
          />
          <div className="actions">
            <Button type="submit">{t('adhesion.personalData.submit')}</Button>
            {<QueryFeedBack query={submitQuery}/>}
          </div>
        </FormGrid>
      )}
    </AdhesionFormLayout>
  );
};

export default AdhesionFormPersonalDataPage;
