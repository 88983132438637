import React from "react";
import Contact from "../../common/contact/Contact";
import "./ContactHomepage.css";

function ContactHomepage() {
  return (
    <div className="contact-homepage" id="contact">
      <div className="contact-details">
        <Contact shouldDisplayPhone={false} />
      </div>
    </div>
  );
}

export default ContactHomepage;
