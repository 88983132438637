import jwt_decode from 'jwt-decode';

class TokenJWT {
    static isValid(token, date) {
        try {
            if (token && token.length > 0) {
                const decodedToken = jwt_decode(token);
                const dateInSec = date / 1000;
                return decodedToken.exp > dateInSec
            } else {
                return false;
            }
        } catch (e) {
            console.error(e);
            return false;
        }
    }
}

export default TokenJWT