import styled from "styled-components";

const FormGrid = styled.form`
  display: grid;
  grid-gap: 1.5em;
  grid-template-columns: repeat(12, minmax(0, 1fr));

  .sub-form {
    display: grid;
    grid-gap: 1.5em;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  & > .field,
  & > .actions,
  & > .MuiAlert-root,
  & > .fields-group,
  & > h2,
  & > h3,
  & > h4,
  & .sub-form > .field,
  & .sub-form > .actions,
  & .sub-form > .MuiAlert-root,
  & .sub-form > .fields-group,
  & .sub-form > h2,
  & .sub-form > h3,
  & .sub-form > h4 {
    grid-column: span 12;
    box-sizing: border-box;
  }

  & > .actions {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    gap: 1rem;
  }

  & > .actions > .MuiAlert-root {
    flex-grow: 1;
    padding: 0.05em 1em;
  }

  & > .actions > button {
    height: min-content;
  }

  & .half-sized,
  & .sub-form .half-sized {
    grid-column: span 6;
  }
`;

export default FormGrid;
