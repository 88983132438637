import React from "react";
import { ReactComponent as BubbleSvg } from "../../assets/images/bubbles.svg";
import styled from "styled-components";


const BubbleRoot = styled.div`
    z-index: -1;
    position: absolute;
    bottom: 0;
    left:0;
`;

const BubbleStyled = styled(BubbleSvg)`
    color: var(--primary-color);
    overflow: hidden;
    width: 55em;
    display: block;
    @media screen and (max-width: 1024px) {
        width: 85vw;
    }
    @media screen and (min-width: 1025px) and (max-width: 1200px){
        width: 45em;
    }
`;

const Bubble = () => (
    <BubbleRoot>
        <BubbleStyled />
    </BubbleRoot>
);


export default Bubble;