import React from "react";
import { useDefaultFieldProps } from "../../common/form/utils";
import TextField, { postalCodeValidationProps } from "../../common/form/TextField";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NumberField from "../../common/form/NumberField";
import { collectiveProcesses } from "./../../../api/mandate/mandate.dto";
import SelectField from "../../common/form/SelectField";

const SubLegalOwnerForm = ({
  value: legalOwner = {},
  setValue: setLegalOwner,
}) => {
  const { t } = useTranslation();
  const { defaultFieldProps } = useDefaultFieldProps(legalOwner, setLegalOwner);
  return (
    <div className="fields-group owners-form">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
                            >
          <label>{t("member.mandate.edition.owners.legal.title")}</label>
        </AccordionSummary>
        <AccordionDetails className="sub-form owner-form">
          <TextField
            className="half-sized"
            {...defaultFieldProps("companyName")}
            label={t("member.mandate.edition.owners.legal.companyNameLabel")}
            required
          />
          <TextField
            className="half-sized"
            {...defaultFieldProps("type")}
            label={t("member.mandate.edition.owners.legal.typeLabel")}
          />
          <TextField
            className="half-sized"
            {...defaultFieldProps("headquartersAddress")}
            label={t("member.mandate.edition.owners.legal.addressLabel")}
            required
          />
          <NumberField
            className="half-sized"
            {...defaultFieldProps("shareCapital")}
            label={t("member.mandate.edition.owners.legal.shareCapitalLabel")}
            unit="€"
          />
          <TextField
            className="half-sized"
            {...defaultFieldProps("headquartersPostalCode")}
            {...postalCodeValidationProps(t('forms.validationMsg.postalCode'))}
            label={t("member.mandate.edition.owners.legal.postalCodeLabel")}
            required
          />
          <TextField
            className="half-sized"
            {...defaultFieldProps("headquartersCity")}
            label={t("member.mandate.edition.owners.legal.cityLabel")}
            required
          />
          <TextField
            className="half-sized"
            {...defaultFieldProps("rcs")}
            label={t("member.mandate.edition.owners.legal.rcsLabel")}
          />
          <TextField
            className="half-sized"
            {...defaultFieldProps("greffeName")}
            label={t("member.mandate.edition.owners.legal.greffeNameLabel")}
          />
          <TextField
            className="half-sized"
            {...defaultFieldProps("siret")}
            label={t("member.mandate.edition.owners.legal.siretLabel")}
          />
          <TextField
            className="half-sized"
            {...defaultFieldProps("registrationNumber")}
            label={t(
              "member.mandate.edition.owners.legal.registrationNumberLabel",
            )}
          />
          <TextField
            className="half-sized"
            {...defaultFieldProps("taxSystem")}
            label={t("member.mandate.edition.owners.legal.taxSystemLabel")}
          />
          <SelectField
            className="half-sized"
            {...defaultFieldProps("collectiveProcess")}
            label={t("member.mandate.edition.owners.legal.collectiveProcessSelect.label")}
            options={collectiveProcesses.map((s) => ({
              label: t(
                `member.mandate.edition.owners.legal.collectiveProcessSelect.valueLabels.${s}`,
              ),
              value: s,
            }))}
          />
          <h4>{t("member.mandate.edition.owners.legal.agentSectionTitle")}</h4>
          <TextField
            className="half-sized"
            {...defaultFieldProps("agentLastName")}
            label={t("member.mandate.edition.owners.legal.agentNameLabel")}
            required
          />
          <TextField
            className="half-sized"
            {...defaultFieldProps("agentFirstName")}
            label={t("member.mandate.edition.owners.legal.agentFirstNameLabel")}
            required
          />
          <TextField
            className="half-sized"
            type="email"
            {...defaultFieldProps("agentEmail")}
            label={t("member.mandate.edition.owners.legal.agentEmailLabel")}
          />
          <TextField
            className="half-sized"
            type="tel"
            {...defaultFieldProps("agentphone")}
            label={t("member.mandate.edition.owners.legal.agentphoneLabel")}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SubLegalOwnerForm;
