import React from 'react';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import InvoiceCard from '../../components/invoice/InvoiceCard';
import { CardsGrid } from '../../components/common/Common';
import { useGetInvoicesQuery } from '../../api/invoice/invoice.api';
import SkeletonCard from '../../components/common/skeleton/SkeletonCard';
import { useParams } from 'react-router-dom';
import QueriesErrors from './../../components/common/error/QueriesErrors';

const InvoicesPage = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const { data, isLoading, ...invoiceQuery } = useGetInvoicesQuery();
  let { mandateId } = useParams();

  const mandateIdIfProvided = (invoice) => {
    if (mandateId) {
      return invoice.mandatId == mandateId;
    }
    return true;
  };

  const recentsFirst = (a, b) =>
    new Date(b.dateCotisation ? b.dateCotisation : b.date) -
    new Date(a.dateCotisation ? a.dateCotisation : a.date);

  const invoices = (data ? [...data] : [])
    .sort(recentsFirst)
    .filter(mandateIdIfProvided);
  const noData = invoiceQuery.isSuccess && invoices.length === 0;

  return (
    <div>
      {isLoading && (
        <CardsGrid>
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
        </CardsGrid>
      )}
      <QueriesErrors queries={[invoiceQuery]} />
      {noData && <Alert severity='info'>{t('member.invoice.none')}</Alert>}
      {data && (
        <CardsGrid>
          {invoices.map((invoice) => (
            <InvoiceCard key={invoice.id} invoice={invoice} />
          ))}
        </CardsGrid>
      )}
    </div>
  );
};

export default InvoicesPage;
