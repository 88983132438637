import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import defaultprepareHeaders from '../common/prepare-header';

export const barApi = createApi({
    reducerPath: "bar",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/bars", prepareHeaders: defaultprepareHeaders }),
    endpoints: (builder) => ({
      getBars: builder.query({
        query: () => ({
          method: "GET"
        }),
      }),
    }),
  });
  
  export const { useGetBarsQuery } = barApi;
  