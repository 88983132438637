import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import translations from "./assets/i18n/translations.json";
import HomePage from "./pages/homepage/HomePage";
import LoginPage from "./pages/login/LoginPage";
import AdhesionFormPageStep1 from "./pages/adhesion/AdhesionFormPersonalDataPage";
import AdhesionFormPageStep2 from "./pages/adhesion/acceptance-step/AdhesionFormMiaDataPage";
import DashboardPage from "./pages/dashboardpage/DashboardPage";
import MemberLayout from "./components/layout/MemberLayout";
import CreateMandatePage from "./pages/mandate/CreateMandatePage";
import CreateDossierPage from "./pages/dossier/CreateDossierPage";
import AdhesionFormConfirmationPage from "./pages/adhesion/confirmation/AdhesionFormConfirmationPage";
import ViewMandatesPage from "./pages/mandate/ViewMandatesPage";
import ProtectedRoute from "./ProtectedRoute";
import InvoicesPage from "./pages/invoice/InvoicesPage";
import ContactMemberPage from "./pages/contact/ContactMemberPage";
import DocumentsPage from "./pages/documents/DocumentsPage";
import MandateOrderRecapPage from './pages/mandate/prestation/MandateOrderRecapPage';
import MandateOrderConfirmationPage from './pages/mandate/prestation/MandateOrderConfirmationPage';
import ForgotPasswordPage from './pages/login/ForgotPasswordPage';
import ChangeForgotPasswordPage from './pages/login/ChangeForgotPasswordPage';

i18n.use(initReactI18next).init({
  resources: translations,
  lng: "fr",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  const { t } = useTranslation();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-pwd" element={<ForgotPasswordPage />} />
        <Route path="/forgot-pwd/:token" element={<ChangeForgotPasswordPage />} />
        <Route path="/adhesion" element={<AdhesionFormPageStep1 />} />
        <Route
          path="/adhesion/:email/acceptance-step"
          element={<AdhesionFormPageStep2 />}
        />
        <Route
          path="/adhesion/:email/confirmation/:status"
          element={<AdhesionFormConfirmationPage />}
        />

        <Route
          path="/member/"
          element={
            <ProtectedRoute>
              <MemberLayout
                title={t("Bienvenue")}
                children={<DashboardPage />}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/member/mandates/create"
          element={
            <ProtectedRoute>
              <MemberLayout
                title={t("member.sidebar.createMandate")}
                children={<CreateMandatePage />}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/member/mandates/:mandateId/edit"
          element={
            <ProtectedRoute>
              <MemberLayout
                title={t("member.sidebar.editMandate")}
                children={<CreateMandatePage />}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/member/mandates/:mandateId/order/recap"
          element={
            <ProtectedRoute>
              <MemberLayout
                title={t("member.mandate.prestation.recap.title")}
                children={<MandateOrderRecapPage />}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/member/mandates/:mandateId/order/confirmation/:status"
          element={
            <ProtectedRoute>
              <MemberLayout
                title={t("member.mandate.prestation.confirmation.title")}
                children={<MandateOrderConfirmationPage />}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/member/mandates/inprogress"
          element={
            <ProtectedRoute>
              <MemberLayout
                title={t("member.sidebar.mandates")}
                children={<ViewMandatesPage filterMandates={m => (m.status === "HORS_LIGNE" || m.status === "PUBLIE")} />}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/member/mandates/history"
          element={
            <ProtectedRoute>
              <MemberLayout
                title={t("member.sidebar.mandates")}
                children={<ViewMandatesPage filterMandates={m => (m.status === "PERDU" || m.status === "VENDU")} />}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/member/create-dossier"
          element={
            <ProtectedRoute>
              <MemberLayout
                title={t("member.sidebar.createCase")}
                children={<CreateDossierPage />}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/member/invoices"
          element={
            <ProtectedRoute>
              <MemberLayout
                title={t("member.sidebar.invoices")}
                children={<InvoicesPage />}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/member/invoices/:mandateId"
          element={
            <ProtectedRoute>
              <MemberLayout
                title={t("member.sidebar.invoices")}
                children={<InvoicesPage/>}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/member/contact"
          element={
            <ProtectedRoute>
              <MemberLayout
                title={t("home.contact.contact")}
                children={<ContactMemberPage />}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/member/documents"
          element={
            <ProtectedRoute>
              <MemberLayout
                title={t("member.sidebar.docs")}
                children={<DocumentsPage />}
              />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
