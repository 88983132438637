import React from 'react';
import MuiTextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import './form.css';
import Tooltip from '@mui/material/Tooltip';

const TextField = ({
  name,
  label,
  value = '',
  className = '',
  emptyMeansUndefined = true,
  onChange = (e) => {},
  ...props
}) => {
  const fieldId = 'field-' + name;
  return (
    <div className={'field txt-field ' + (className ?? '')}>
      <Tooltip title={label} placement="top-start">
        <InputLabel htmlFor={fieldId}>{label}</InputLabel>
      </Tooltip>
      <MuiTextField
        id={fieldId}
        size="small"
        name={name}
        value={value ?? ''}
        {...props}
        fullWidth
        onChange={(e) => {
          if (e.target.value === '' && emptyMeansUndefined) {
            onChange({ ...e, target: { ...e.target, value: null } });
          } else onChange(e);
        }}
      />
    </div>
  );
};

export const postalCodeValidationProps = (errorMsg) => ({
  inputProps: {
    pattern: "^\\d{2}\\s?\\d{3}$",
    title: errorMsg,
  }
})

export default TextField;
