import React from "react";
import { Grid } from "@mui/material";
import OurServicesItem from "./OurServicesItem";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ServicesTitle = styled.h4`
  padding-bottom: 3em;
`;

const OurServicesList = () => {
  const { t } = useTranslation();
  /** @type string[] */
  const services = t("home.ourServices.items", {
    returnObjects: true,
    defaultValue: [],
  });
  const renderOurServicesItem = () => services.map((d, i) => <OurServicesItem key={i} description={d} />);
  return (
    <Grid item>
      <ServicesTitle>{t("home.ourServices.title")}</ServicesTitle>
      {renderOurServicesItem()}
    </Grid>
  );
}

export default OurServicesList;
