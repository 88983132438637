import React from 'react';
import AdhesionFormLayout, {
  steps,
} from '../../../components/adhesion-form/layout/AdhesionFormLayout';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/common/form/Button';
import { Link, useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import styled from 'styled-components';

const LinksLayout = styled.div`
  margin-top: 5em;
  display: flex;
  justify-content: center;
  gap: 2em;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  & button {
    min-width: 20em;
  }
`;

const ContactLayout = styled.div`
  width: fit-content;
  margin: auto;
  margin-top: 5em;
  & li {
    padding: 0.3em;
    text-align: start;
  }
  & li .contact-label {
    min-width: 10em;
    padding-right: 0.3em;
  }
`;

const AdhesionFormConfirmationPage = () => {
  const { t } = useTranslation();
  const { status, email } = useParams();

  return (
    <AdhesionFormLayout step={steps.paymentData}>
      <Box sx={{ textAlign: 'center' }}>
        <h1>{t(`adhesion.confirmation.${status}.title`)}</h1>
        <p>{t(`adhesion.confirmation.${status}.message`)}</p>
        {['INTERNAL_ERROR'].includes(status) && (
          <ContactLayout>
            <h4>{t(`adhesion.confirmation.INTERNAL_ERROR.contact.label`)}</h4>
            <ul>
              <li>
                <span className="contact-label">
                  {t(
                    `adhesion.confirmation.INTERNAL_ERROR.contact.email.label`,
                  )}
                </span>
                <a
                  href={`mailto:${t(
                    `adhesion.confirmation.INTERNAL_ERROR.contact.email.value`,
                  )}`}
                >
                  {t(
                    `adhesion.confirmation.INTERNAL_ERROR.contact.email.value`,
                  )}
                </a>
              </li>
              <li>
                <span className="contact-label">
                  {t(
                    `adhesion.confirmation.INTERNAL_ERROR.contact.phone.label`,
                  )}
                </span>
                <a
                  href={`tel:${t(
                    `adhesion.confirmation.INTERNAL_ERROR.contact.phone.value`,
                  )}`}
                >
                  {t(
                    `adhesion.confirmation.INTERNAL_ERROR.contact.phone.value`,
                  )}
                </a>
              </li>
            </ul>
          </ContactLayout>
        )}
        <LinksLayout>
          {['OK'].includes(status) && (
            <Link to={'/login'}>
              <Button>{t('adhesion.confirmation.loginLink')}</Button>
            </Link>
          )}
          {['KO', 'CANCEL'].includes(status) && (
            <Link to={`/adhesion/${email}/acceptance-step`}>
              <Button>{t('adhesion.confirmation.tryAgainLink')}</Button>
            </Link>
          )}
          <Link to={'/'}>
            <Button className="secondary">
              {t('adhesion.confirmation.homeLink')}
            </Button>
          </Link>
        </LinksLayout>
      </Box>
    </AdhesionFormLayout>
  );
};

export default AdhesionFormConfirmationPage;
