import React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

const RequiredValidation = ({ value, name }) => (
  <input
    className="hidden-validation"
    name={name+'_required'}
    style={{ width: 0, height: 0, opacity: 0, zIndex: -1 }}
    value={value}
    onChange={() => {}}
    required
  />
);

/**
 * options must be { label: string, value: any }[]
 * @param {options} props
 * @returns
 */
const RadioField = ({ options, name, label, className = "", required = false, ...props }) => {
  const fieldId = "field-" + name;
  return (
    <FormControl className={"field radio-field " + className}>
      <FormLabel id={fieldId + "-label"}>{label}</FormLabel>
      {required && <RequiredValidation value={props.value} name={props.name} />}
      <RadioGroup {...props}>
        {options.map((opt) => (
          <FormControlLabel control={<Radio />} {...opt} key={opt.value}/>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioField;
