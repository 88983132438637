import React from "react";
import { Grid } from "@mui/material";
import "./OurServices.css";
import OurServicesList from "./OurServicesList";

const OurServices = () => {
  return (
    <Grid
      container
      className="container"
      flexDirection={"column"}
      justifyContent={"space-around"}
      id="service"
    >
      <Grid
        container
        item
        flexDirection={"row"}
        justifyContent={"space-around"}
        marginTop={"3em"}
      >
        <OurServicesList />
        <div className="source-img" />
      </Grid>
    </Grid>
  );
};

export default OurServices;
