import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';

const PackPrestationCheckBoxList = ({ lst, setLst, prestationOpts, nbMax }) => {
  const {t} = useTranslation();
  const handleCheckboxChange = (event, id) => {
    const selectPrestator = event.target.checked;
    let prestationSelecteds = [...lst];
    if (selectPrestator) {
      prestationSelecteds.push(id);
    } else {
      prestationSelecteds = prestationSelecteds.filter((pId) => pId !== id);
    }
    setLst(prestationSelecteds);
  };
  return (
    <FormGroup>
      <input
        type="text"
        name={`pack-pretator`}
        style={{ opacity: 0, float: 'left', width: 0, height: 0 }}
        value={lst.length}
        title={t('forms.validationMsg.nbSelectionRequired')}
        onChange={() => {}} //Avoid error without switch to readonly (that will skip the validation)
        pattern={`${nbMax}`}
      />
      {prestationOpts.map(({ label, id }) => {
        const isSelected = lst.includes(id);
        return (
          <FormControlLabel
            key={id}
            control={
              <Checkbox
                checked={isSelected}
                disabled={!isSelected && lst.length >= nbMax}
                onChange={(e) => handleCheckboxChange(e, id)}
              />
            }
            label={label}
          />
        );
      })}
    </FormGroup>
  );
};

export default PackPrestationCheckBoxList;
