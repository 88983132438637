import React from "react";
// @ts-ignore
import { Grid } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CustomizedButton from "../../../common/CustomizedButton";
import { useSelector } from "react-redux";
import { isUserLoggedIn } from "../../../../redux/slices/userSlice";

const LoginText = styled.span`
  text-decoration: none;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
`;
const AccountText = styled.span`
  text-decoration: none;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
`;

function MyAccount() {
  const isLogin = useSelector(isUserLoggedIn);
  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      {!isLogin ? (
        <Link to={"/login"}>
          <CustomizedButton width="7.6875em" height="2.1875em">
            <LoginText>se connecter</LoginText>
          </CustomizedButton>
        </Link>
      ) : (
        <Link to={"/member"}>
          <CustomizedButton
            width="9.0625em"
            height="2.1875em"
            startIcon={
              <PersonOutlineOutlinedIcon style={{ color: "white" }} />
            }>
            <AccountText>Mon compte</AccountText>
          </CustomizedButton>
        </Link>
      )}
    </Grid>
  );
}

export default MyAccount;
