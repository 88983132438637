import React, { useState } from 'react';
import { Alert, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextField from '../../components/common/form/TextField';
import Header from '../../components/common/header/Header';
import { useDefaultFieldProps } from '../../components/common/form/utils';
import './LoginPage.css';
import { Link } from 'react-router-dom';
import { useForgotPwdMutation } from '../../api/auth/auth.api';
import QueriesErrors from '../../components/common/error/QueriesErrors';
import Button from '../../components/common/form/Button';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: '',
  });

  const [sendForgotPasswordEmail, { isSuccess, ...sendEmailQuery }] =
    useForgotPwdMutation();

  const { defaultFieldProps } = useDefaultFieldProps(formData, setFormData);

  const handleSubmit = (e) => {
    e.preventDefault();
    sendForgotPasswordEmail(formData.email);
  };

  return (
    <>
      <Header title={t('login.forgotPwd.title')}></Header>
      <Grid
        container
        justifyContent={'center'}
        alignContent={'center'}
        alignItems={'center'}
        minHeight={'100vh'}
      >
        <Grid className="loginpage" item md={8} paddingBottom={'20em'}>
          <form className="login-form" onSubmit={handleSubmit}>
            <Grid
              container
              flexDirection={'column'}
              justifyContent={'center'}
              alignContent={'center'}
              alignItems={'center'}
            >
              <Grid item>
                {isSuccess && (
                  <Alert severity="success">
                    {t('login.forgotPwd.emailSentMsg')}
                  </Alert>
                )}
                <QueriesErrors
                  queries={[sendEmailQuery]}
                  sx={{ maxWidth: '63em', whiteSpace: 'pre-line' }}
                />
              </Grid>
              <Grid
                item
                container
                flexDirection={'column'}
                justifyContent={'space-around'}
                minHeight={'10em'}
                md={6}
              >
                <Grid item>
                  <TextField
                    type="email"
                    {...defaultFieldProps('email')}
                    label={t('login.emailLabel')}
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction={'row-reverse'}
                gap={'2em'}
                marginTop={'5em'}
                md={6}
              >
                <Grid item md={12} sm={12} lg={'auto'}>
                  <Button type="submit" disabled={isSuccess} fullWidth>
                    {t('login.forgotPwd.sendEmailSubmit')}
                  </Button>
                </Grid>
                <Grid item md={12} sm={12} lg={'auto'}>
                  <Link to={'/login'} >
                    <Button className="secondary" fullWidth>
                      {t('login.forgotPwd.changePwd.loginPageLink')}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default ForgotPasswordPage;
