export const mandateTypes = ["EXCLUSIF", "NON_EXCLUSIF"];
export const propertyUsages = [
  "RESIDENCE_PRINCIPALE",
  "RESIDENCE_SECONDAIRE",
  "BIEN_USAGE_LOCATIF_LIBRE",
  "BIEN_USAGE_LOCATIF_LOUE",
];
export const collectiveProcesses = [
  "SAVE",
  "REDRESSEMENT",
  "LIQUIDATION",
];

export const notClosedMandateStatus = [
  'HORS_LIGNE',
  'PUBLIE'
]
