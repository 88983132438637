import React from "react";
import { Grid } from "@mui/material";
import { navBar } from "../../../../constants/route";
import NavBarItem from "./NavBarItem";

function NavBar() {
  const renderNavItem = () =>
    navBar.map(({ label, link }, i) => (
      <NavBarItem key={i} label={label} link={link} />
    ));
  return (
    <Grid
      container
      justifyContent={"space-around"}
      alignContent={"center"}
      alignItems={"center"}>
      {renderNavItem()}
    </Grid>
  );
}

export default NavBar;
