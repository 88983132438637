import React from "react";
import { Grid } from "@mui/material";
import "./OurServicesWork.css";
import { useTranslation } from "react-i18next";
import { InvisibleBlockForGrid, SectionTitle } from "../../common/Common";
import AdvantagesItem from "./AdvantagesItem";
import FreeBenefitsItem from "./FreeBenefitsItem";

const OurServiceWork = () => {
  const { t } = useTranslation();
  /** @type string[] */
  const advantages = t("home.ourServicesWork.advantages", {
    returnObjects: true,
    defaultValue: [],
  });
  /** @type string[] */
  const freeBenefitsMember = t("home.ourServicesWork.freeBenefitsMember", {
    returnObjects: true,
    defaultValue: [],
  });
  const renderAdvantages = () =>
    advantages.map((d, i) => <AdvantagesItem key={i} description={d} />);
  const renderFreeBenefits = () =>
    freeBenefitsMember.map((d, i) => (
      <FreeBenefitsItem key={i} description={d} />
    ));
  return (
    <Grid
      container
      flexDirection={"column"}
      id="avantages"
      className="our-services-work"
    >
      <Grid
        container
        item
        flexDirection={"row"}
        justifyContent={"space-around"}
      >
        <SectionTitle>{t("home.ourServicesWork.title")}</SectionTitle>
        <InvisibleBlockForGrid />
      </Grid>
      <Grid
        container
        item
        flexDirection={"row"}
        justifyContent={"space-around"}
        className="work-content"
      >
        <Grid item style={{ zIndex: 2, margin: "auto" }}>
          <div className="work-image"></div>
        </Grid>
        <Grid item md={7} className="work-text" style={{ zIndex: 1 }}>
          {renderAdvantages()}
          {renderFreeBenefits()}
          <div className="white-rect"></div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OurServiceWork;
