import React from "react";
import { useTranslation } from "react-i18next";

const Contact = ({ shouldDisplayPhone = true }) => {
    const { t } = useTranslation();
    return (
        <div>
            <p className="bold">{t("home.contact.title")}</p>
            <p>{t("home.contact.name")}</p>
            <p className="adress">
                {t("home.contact.adress1")} <br />
                {t("home.contact.adress2")} <br />
                {t("home.contact.adress3")} <br />
            </p>
            <p><b>{t("home.contact.labelEmail")} : </b>{t("home.contact.email")}</p>
            {shouldDisplayPhone && (<p><b>{t("home.contact.labelTel")} : </b>{t("home.contact.tel")}</p>)}
            {shouldDisplayPhone && (<p><b>{t("home.contact.labelPortable")} : </b>{t("home.contact.telPortable")}</p>)}
        </div>
    )
};

export default Contact;