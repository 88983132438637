import React from 'react';
import { Box, LinearProgress, Skeleton } from '@mui/material';

const SkeletonForm = () => {
  return (
    <Box>
      <LinearProgress/>
      <Skeleton width={150} height={40}/>
      <Skeleton height={50}/>
      <Skeleton width={200} height={40}/>
      <Skeleton height={50}/>
      <Skeleton width={150} height={40}/>
      <Skeleton height={50}/>
      <Skeleton width={150} height={40}/>
      <Skeleton height={50}/>
      <Skeleton width={200} height={40}/>
      <Skeleton height={50}/>
      <Skeleton width={150} height={40}/>
      <Skeleton height={50}/>
    </Box>
  );
};

export default SkeletonForm;
