import React from 'react';
import { Alert, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const QueryFeedBack = ({ query, type = "default", successMsg = undefined, ...props }) => {
  const { t } = useTranslation();

  const { isSuccess, isLoading, isError, error } = query;
  const errorStatusPrefix = !!error?.status ? `${error.status}: ` : '';
  return (
    <>
      {isLoading && <CircularProgress />}
      {isSuccess && (
        <Alert
          severity="success"
          {...props}
        >
            {successMsg || t(`forms.successMsg.${type}`)}
        </Alert>
      )}
      {isError && (
        <Alert severity="error" {...props}>
          {`${errorStatusPrefix}${t(error.data.message)}`}
        </Alert>
      )}
    </>
  );
};

export default QueryFeedBack;
