import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import "./OurServicesItem.css";

const OurServicesItem = ({ description }) => {
  return (
    <div className="our-services-item">
      <div className="description">{description}</div>
      <div className="border"></div>
      <div className="check">
        <CheckIcon />
      </div>
    </div>
  );
}

export default OurServicesItem;
