import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';

const DownloadSnackbar = ({ isLoading }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(isLoading);
  }, [isLoading]);
  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom'}}
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
      message={t('common.download.inProgress')}
    />
  );
};

export default DownloadSnackbar;
