import React, { useState, useEffect } from 'react';
import Button from '../../common/form/Button';
import DateField from '../../common/form/DateField';
import TextField, {
  postalCodeValidationProps,
} from '../../common/form/TextField';
import SelectField from '../../common/form/SelectField';
import { useTranslation } from 'react-i18next';
import {
  mandateTypes,
  notClosedMandateStatus,
} from '../../../api/mandate/mandate.dto';
import NumberField from '../../common/form/NumberField';
import { useGetPropertyTypesQuery } from '../../../api/property-type/property-type.api';
import {
  useCreateMandateMutation,
  useGetMandateQuery,
  usePrepareMandateFormQuery,
  useUpdateMandateContentMutation,
} from '../../../api/mandate/mandate.api';
import { onInvalidSubAccordionForm, useDefaultFieldProps } from '../../common/form/utils';
import PercentField from '../../common/form/PercentField';
import { useNavigate } from 'react-router-dom';
import { Modal, Paper } from '@mui/material';
import FormGrid from './../../common/form/FormGrid';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../../redux/slices/userSlice';
import { exportDocument } from '../utils';
import QueriesErrors from './../../common/error/QueriesErrors';
import SkeletonForm from '../../common/skeleton/SkeletonForm';
import SubOwnerForm from './SubOwnerForm';
import MandateClosureForm from './MandateClosureForm';
import './MandateClosureModal.css';
import QueryFeedBack from './../../query/QueryFeedback';

const defaultFormData = {
  mandateNumber: '',
  internalMandateNumber: '',
  mandateType: '',
  startDate: new Date(),
  endDate: new Date(),
  propertyAddress: '',
  postalCode: '',
  city: '',
  propertyTypeId: '',
  propertyUsage: '',
  estimatedPrice: '',
  mandateRate: '',
  owner: {
    isOwnerNatural: true,
    naturalOwners: [],
    legalOwner: {},
  },
};

const MandateContentForm = ({ mandateId }) => {
  const isInCreationMode = !mandateId;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const member = useSelector(selectUserData);

  // ---- QUERIES ---- //
  const {
    data: formInitData,
    isLoading: isFormInitQueryLoading,
    refetch: reloadForm,
    ...formInitQuery
  } = usePrepareMandateFormQuery(mandateId);
  const {
    data: propertyTypes,
    isLoading: isPropertyTypesQueryLoading,
    ...propertyTypesQuery
  } = useGetPropertyTypesQuery({});
  const {
    data: mandate,
    isLoading: isMandateQueryLoading,
    ...mandateQuery
  } = useGetMandateQuery(mandateId, { skip: isInCreationMode });
  const isQueriesLoading =
    isFormInitQueryLoading ||
    isPropertyTypesQueryLoading ||
    isMandateQueryLoading;

  const canMandateBeClosed = notClosedMandateStatus.includes(mandate?.status);

  // ---- MUTATIONS ---- //
  const [createMandate, { data: creationData, ...creationQuery }] =
    useCreateMandateMutation();
  const [updateMandateContent, editionQuery] =
    useUpdateMandateContentMutation();

  // ---- FORM DATA & LOGIC ---- //
  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    setFormData({
      ...defaultFormData,
      ...formInitData,
    });
  }, [formInitData]);

  const { defaultFieldProps, defaultSubFormProps } = useDefaultFieldProps(
    formData,
    setFormData,
  );

  const save = isInCreationMode
    ? (body) => createMandate(body)
    : (body) => updateMandateContent({ mandateId, body });

  useEffect(() => {
    if (creationQuery.isSuccess) {
      navigate(`/member/mandates/${creationData.id}/edit`);
      reloadForm();
    }
  }, [creationQuery.isSuccess]);

  // ---- MODAL LOGIC ---- //

  const [isClosureFormOpen, setIsClosureFormOpen] = useState(false);

  // ---- UTILS ---- //

  const exportMandate = () => {
    exportDocument(mandate, member);
  };

  return (
    <>
      <QueriesErrors
        queries={[mandateQuery, formInitQuery, propertyTypesQuery]}
      />

      {(isQueriesLoading && <SkeletonForm />) || (
        <>
          <FormGrid
            className="mandate-edition-page"
            onSubmit={(e) => {
              e.preventDefault();
              save(formData);
              return false;
            }}
            onInvalid={onInvalidSubAccordionForm}  
          >
            <TextField
              {...defaultFieldProps('mandateNumber')}
              label={t('member.mandate.edition.mandateNumberLabel')}
              disabled
              required
            />
            <TextField
              {...defaultFieldProps('internalMandateNumber')}
              label={t('member.mandate.edition.internalMandateNumberLabel')}
            />
            <SelectField
              {...defaultFieldProps('mandateType')}
              label={t('member.mandate.edition.mandateTypeSelect.label')}
              options={mandateTypes.map((s) => ({
                label: t(
                  `member.mandate.edition.mandateTypeSelect.valueLabels.${s}`,
                ),
                value: s,
              }))}
              required
            />
            <DateField
              className="half-sized"
              {...defaultFieldProps('startDate')}
              inputFormat="dd/MM/yyyy"
              label={t('member.mandate.edition.startDateLabel')}
              required
            />
            <DateField
              className="half-sized"
              {...defaultFieldProps('endDate')}
              inputFormat="dd/MM/yyyy"
              label={t('member.mandate.edition.endDateLabel')}
              required
            />
            <TextField
              {...defaultFieldProps('propertyAddress')}
              label={t('member.mandate.edition.addressLabel')}
              required
            />
            <TextField
              className="half-sized"
              {...defaultFieldProps('postalCode')}
              {...postalCodeValidationProps(
                t('forms.validationMsg.postalCode'),
              )}
              label={t('member.mandate.edition.postalCodeLabel')}
              required
            />
            <TextField
              className="half-sized"
              {...defaultFieldProps('city')}
              label={t('member.mandate.edition.cityLabel')}
              required
            />
            <SelectField
              {...defaultFieldProps('propertyTypeId')}
              label={t('member.mandate.edition.propertyTypeLabel')}
              options={(propertyTypes ?? []).map((s) => ({
                label: s.label,
                value: s.id,
              }))}
              required
            />
            <NumberField
              className="half-sized"
              {...defaultFieldProps('estimatedPrice')}
              label={t('member.mandate.edition.estimatedPriceLabel')}
              unit={'€'}
              required
            />
            <PercentField
              className="half-sized"
              {...defaultFieldProps('mandateRate')}
              label={t('member.mandate.edition.mandateRateLabel')}
              required
            />
            <SubOwnerForm {...defaultSubFormProps('owner')} />
            <div className="actions">
              {(isInCreationMode && (
                <>
                  <Button type="submit">
                    {t('member.mandate.edition.submit.create')}
                  </Button>
                </>
              )) || (
                <>
                  <Button type="submit">
                    {t('member.mandate.edition.submit.modify')}
                  </Button>
                  <Button type="button" onClick={exportMandate}>
                    {t('member.mandate.edition.submit.export')}
                  </Button>
                  {canMandateBeClosed && (
                    <Button
                      type="button"
                      onClick={() => setIsClosureFormOpen(true)}
                    >
                      {t('member.mandate.edition.submit.close')}
                    </Button>
                  )}
                </>
              )}
              {!editionQuery.isError && !editionQuery.isSuccess && (
                <QueryFeedBack
                  query={creationQuery}
                  type="create"
                  successMsg={t(
                    'member.mandate.edition.successMsg.contentCreate',
                  )}
                />
              )}
              <QueryFeedBack query={editionQuery} type="save" />
            </div>
          </FormGrid>
          {canMandateBeClosed && (
            <Modal
              open={isClosureFormOpen}
              onClose={() => setIsClosureFormOpen(false)}
            >
              <Paper>
                <MandateClosureForm
                  mandateId={mandateId}
                  onSuccess={() => setIsClosureFormOpen(false)}
                />
              </Paper>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default MandateContentForm;
