import React from 'react';
import DateField from '../../../common/form/DateField';
import NumberField from '../../../common/form/NumberField';
import SelectField from '../../../common/form/SelectField';
import { useDefaultFieldProps } from '../../../common/form/utils';

const FactoryField = ({
  field,
  formData,
  setFormData,
  t,
  selectOptionsMap,
  ...props
}) => {
  const { defaultFieldProps } = useDefaultFieldProps(formData, setFormData);

  switch (field.type) {
    case 'date':
      return (
        <DateField
          className="half-sized"
          {...defaultFieldProps(field.name)}
          inputFormat="dd/MM/yyyy"
          label={field.label}
          required
          {...props}
        />
      );
    case 'select':
      let selectOptions = getOptionsForSelect(
        field,
        selectOptionsMap,
        (value) => t(`${field.name}Select.valueLabels.${value}`),
      );
      return (
        <SelectField
          className="half-sized"
          {...defaultFieldProps(field.name)}
          label={field.label}
          options={selectOptions}
          disabled={!field.options}
          required
          {...props}
        />
      );
    case 'number':
      return (
        <NumberField
          className="half-sized"
          {...defaultFieldProps(field.name)}
          label={field.label}
          unit={field.unit}
          unitPosition="end"
          required
          {...props}
        />
      );
    default:
      return null;
  }
};

const getOptionsForSelect = (field, availableOptions, t) => {
  if (Array.isArray(field.options))
    return field.options.map((value) => ({
      label: t(value),
      value,
    }));
  if (field.options in availableOptions) {
    return availableOptions[field.options].map((value) => {
      if (typeof value === 'string') {
        return {
          label: t(value),
          value,
        };
      }
      return value;
    });
  }
  console.warn(
    'FactoryField:getOptionsForSelect -> No options found for ' + field.options,
  );
  return [];
};

export default FactoryField;
