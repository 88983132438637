import { adhesionApi } from './adhesion/adhesion.api';
import { authApi } from './auth/auth.api';
import { barApi } from './bar/bar.api';
import { documentsApi } from './documents/documents.api';
import { invoiceApi } from './invoice/invoice.api';
import { mandateApi } from './mandate/mandate.api';
import { prestationApi } from './prestation/prestation.api';
import { propertyTypeApi } from './property-type/property-type.api';
import { userApi } from './user/user.api';

export const apis = [adhesionApi, authApi, barApi, documentsApi, invoiceApi, mandateApi, prestationApi, propertyTypeApi, userApi]