import React from 'react';
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import "./SkeletonCard.css";
import { Skeleton } from '@mui/material';

const SkeletonCard = () => {
  return (
    <Card className="skeleton-card">
      <CardActionArea>
        <Skeleton variant="rectangular" width={320} height={213} />
        <CardContent>
          <Skeleton />
          <Skeleton />
          <Skeleton width="60%" />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SkeletonCard;
