import "./form.css";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

const Button = styled(MuiButton)`
  background: var(--dark-red);
  color: var(--white);
  min-width: min-content;

  :hover {
    background: var(--bright-red);
  }

  :disabled {
    background: rgba(var(--dark-red), 50%);
    color: var(--grey);
  }

  &.secondary {
    background: var(--white);
    color: var(--dark-red);
    border: 1px solid var(--dark-red);
  }
  &.secondary:hover {
    background: #ffe8e8;
  }
`;

export default Button;
