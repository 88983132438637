import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPrestationOrderStatusQuery } from '../../../api/mandate/mandate.api';
import LinearProgress from '@mui/material/LinearProgress';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import './MandateOrderRecapPage.css';
import Container from '@mui/material/Container';
import {normalizeFloat, normalizeInt} from '../../../utils/math.util';
import SystemPayForm from '../../../components/common/payment/SytempayForm';
import { useTranslation } from 'react-i18next';

const MandateOrderRecapPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(undefined, { keyPrefix: 'member.mandate.prestation.recap'});
  const { mandateId } = useParams();

  const {
    data,
    isSuccess: isRecapQuerySuccess,
    isLoading: isRecapQueryLoading,
    isError: isRecapQueryError,
  } = useGetPrestationOrderStatusQuery(mandateId);
  const recap = data?.recap;
  const paymentForm = data?.paymentForm;

  useEffect(() => {
    if (isRecapQueryError) {
      navigate(`/member/mandates/${mandateId}/edit`);
    }
  }, [isRecapQueryError]);

  return (
    <Container className="order-recap">
      {isRecapQueryLoading && <LinearProgress />}
      {isRecapQuerySuccess && (
        <>
          <h3>{t('orderTitle')}</h3>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('labelColumn')}</TableCell>
                <TableCell>{t('prestatorColumn')}</TableCell>
                <TableCell align="right">{t('priceColumn')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(recap?.invoiceLines ?? []).map((row) => (
                <TableRow key={row.label}>
                  <TableCell component="th" scope="row">
                    {row.label}
                  </TableCell>
                  <TableCell>{row.prestataireLabel}</TableCell>
                  <TableCell align="right">{normalizeFloat(row.priceHT)} €</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="total">
            <Table>
              <TableRow>
                <TableCell className='list-title'>{t('totalHT')}</TableCell>
                <TableCell align="right">{normalizeFloat(recap.totalHT)} €</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='list-title'>{t('tva')}</TableCell>
                <TableCell align="right">{normalizeInt(recap.tva*100)} %</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='list-title'>{t('totalTTC')}</TableCell>
                <TableCell align="right">{normalizeFloat(recap.totalTTC)} €</TableCell>
              </TableRow>
            </Table>
          </div>
          <SystemPayForm
            body={paymentForm}
            sendLabel={`${t('submit')}`}
          />
        </>
      )}
    </Container>
  );
};

export default MandateOrderRecapPage;
