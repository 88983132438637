import React, { useEffect } from 'react';
import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelectField from '../../../common/form/SelectField';
import { WarningMessage } from './PrestationsForm';
import Prestators from './prestators/Prestators';
import {
  useListPackByCategoryQuery,
  useListPrestationsDoneQuery,
} from '../../../../api/prestation/prestation.api';
import LinearProgress from '@mui/material/LinearProgress';
import QueriesErrors from '../../../common/error/QueriesErrors';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import { useDefaultFieldProps } from '../../../common/form/utils';
import PackPrestationCheckBoxList from './pack/PackPrestationCheckBoxList';

const PrestationTitle = styled.h4`
  margin-bottom: 0;
`;

const defaultPackFormData = {
  prestationSelecteds: [],
};


const SelectedPackForm = ({ selectedPack, defaultSubLstFieldProps, defaultSubFormProps, t }) => {
  return (
    <>
      <h4>{t('pack.additionalPrestations')}</h4>
      <PackPrestationCheckBoxList
        {...defaultSubLstFieldProps('prestationSelecteds')}
        prestationOpts={selectedPack.prestationOptions}
        nbMax={selectedPack.nbSelection}
      />
      <h4>{t('pack.prestators')}</h4>
      <WarningMessage message={t('warning.callPrestator')} />{' '}
      <Prestators
        {...defaultSubFormProps('prestataireId')}
        prestators={selectedPack?.prestators ?? []}
        name="packs"
      />
    </>
  );
};

const PrestationPackSubForm = ({
  mandate,
  value: formData,
  setValue: setFormData,
}) => {
  formData ??= defaultPackFormData;

  const { t } = useTranslation(undefined, {
    keyPrefix: 'member.mandate.prestation',
  });

  const {
    data: prestationDone,
    isLoading: isPrestationDoneQueryLoading,
    isSuccess: isPrestationDoneQuerySuccess,
  } = useListPrestationsDoneQuery({ mandateId: mandate.id });
  const {
    data: packByCategories,
    isLoading: isPackByCategoriesQueryLoading,
    isSuccess: isPackByCategoriesQueryIsSuccess,
    ...packByCategoriesQuery
  } = useListPackByCategoryQuery({
    postalCode: mandate.content.postalCode,
    typologyId: mandate.propertyDetails.typology?.id,
    propertyTypeId: mandate.content.propertyType.id,
  });
  const isQueriesLoading =
    isPrestationDoneQueryLoading || isPackByCategoriesQueryLoading;
  const isQueriesSuccess =
    isPrestationDoneQuerySuccess || isPackByCategoriesQueryIsSuccess;
  const isAlreadyDone = isQueriesSuccess && !!prestationDone?.packDone;

  const { defaultFieldProps, defaultSubFormProps, defaultSubLstFieldProps } =
    useDefaultFieldProps(formData, setFormData);

  useEffect(() => {
    if (!formData.packTarifId) {
      setFormData(null);
    } else {
      setFormData({
        ...formData,
        prestationSelecteds: [],
      });
    }
  }, [formData.packTarifId]);


  return (
    <>
      <PrestationTitle>{t('pack.label')}</PrestationTitle>
      <QueriesErrors queries={[packByCategoriesQuery]} />
      {isQueriesLoading && <LinearProgress />}
      {isQueriesSuccess &&
        ((!packByCategories?.length && (
          <Alert severity="info">{t('pack.none')}</Alert>
        )) || (
          <div className="fields-group">
            {packByCategories.map((category) => (
              <Accordion key={category.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {category.label}
                </AccordionSummary>
                {isAlreadyDone && (
                  <AccordionDetails>
                    <Alert severity="info">{t('pack.alreadyDone')}</Alert>
                  </AccordionDetails>
                )}
                {!isAlreadyDone && (
                  <AccordionDetails>
                    <SelectField
                      {...defaultFieldProps('packTarifId')}
                      label={t('pack.typeLabel')}
                      options={category.packs.map(
                        ({
                          packTarifId,
                          includedPrestation,
                          nbSelection,
                          price,
                        }) => ({
                          label: t(
                            nbSelection <= 1
                              ? 'pack.packLabel.onePresta'
                              : 'pack.packLabel.manyPresta',
                            {
                              prestationLabel: includedPrestation.label,
                              nbSelection,
                              price,
                            },
                          ),
                          value: packTarifId,
                        }),
                      )}
                    />
                    {!!formData.packTarifId && (
                      <SelectedPackForm
                        selectedPack={category.packs.find(
                          (pack) => pack.packTarifId == formData?.packTarifId,
                        )}
                        defaultSubFormProps={defaultSubFormProps}
                        defaultSubLstFieldProps={defaultSubLstFieldProps}
                        t={t}
                      />
                    )}
                  </AccordionDetails>
                )}
              </Accordion>
            ))}
          </div>
        ))}
    </>
  );
};

export default PrestationPackSubForm;
