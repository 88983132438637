import React from 'react';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

const QueriesErrors = ({ queries, sx = {}, ...props }) => {
  const { t } = useTranslation();
  return (
    <>
      {queries
        .filter(({ isError }) => isError)
        .map(({ error, requestId = -1 }) => {
          const errorStatusPrefix = !!error.status ? `${error.status}: ` : '';
          return (
          <Alert severity="error" sx={{ width: '100%', ...sx }} key={requestId} {...props}>
            {`${errorStatusPrefix}${t(error.data.message)}`}
          </Alert>
        )})}
    </>
  );
};

export default QueriesErrors;
