export const useDefaultFieldProps = (formState, setFormState) => ({
  defaultFieldProps: (name, { parseBoolean, zeroMeanNull } = { parseBoolean: false, zeroMeanNull: false }) => ({
    name,
    value: formState[name],
    onChange: (event) => {
      let value = event;
      if (event != null && "target" in event) {
        const target = event.target;
        value = target.type === "checkbox" ? target.checked : target.value;
      }

      if (!zeroMeanNull && value === 0) {
        value = 0;
      } else {
        value = value ? value : null
      }

      if (parseBoolean) {
        value = value === "true";
      }

      setFormState({
        ...formState,
        [name]: value,
      });

      return value;
    },
  }),

  defaultSubFormProps: (fieldName) => {
    const value = formState[fieldName];
    const setValue = (newValue) =>
      setFormState({
        ...formState,
        [fieldName]: newValue,
      });
    return {
      value,
      setValue,
    };
  },

  defaultSubLstFieldProps: (lstFieldName) => ({
    lst: formState[lstFieldName],
    setLst: (lstValue) =>
      setFormState({
        ...formState,
        [lstFieldName]: lstValue,
      }),
    defaultFieldProps: (name, idx) => ({
      name: `${lstFieldName}_${idx}_${name}`,
      value: formState[lstFieldName][idx][name],
      onChange: (event) => {
        let value = event;
        if (event != null && "target" in event) {
          const target = event.target;
          value = target.type === "checkbox" ? target.checked : target.value;
        }

        const newLst = [...formState[lstFieldName]];
        newLst[idx] = {
          ...newLst[idx],
          [name]: value,
        };

        setFormState({
          ...formState,
          [lstFieldName]: newLst,
        });

        return value;
      },
    }),
  }),
});

export const strongPasswordRegex =
  "^(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.*[0-9])(?=.*[a-z]).{8,}$";

export const onInvalidSubAccordionForm = (e) => {
  const name = e.target?.name ?? '';
  const accordeonE = document.querySelector(`.MuiAccordion-root:has( input[name='${name}']) > .MuiAccordionSummary-root:not(.Mui-expanded)`);
  if(accordeonE) accordeonE.click();
  else console.debug('no accordeon found');
}