import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import defaultprepareHeaders from '../common/prepare-header';

export const mandateApi = createApi({
  reducerPath: 'mandate',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/mandates',
    prepareHeaders: defaultprepareHeaders,
  }),
  endpoints: (builder) => ({
    getMandates: builder.query({
      query: (query) => ({
        url: ``,
        params: query,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Mandate', id })), 'Mandates']
          : ['Mandates'],
    }),
    prepareMandateForm: builder.query({
      query: (mandateId) => ({
        url: `/content/pre-post`,
        params: {
          mandateId,
        },
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        !!arg ? [{ type: 'Mandate', id: arg }] : ['MandateContentForm'],
    }),
    preparePropertyDetailsForm: builder.query({
      query: (mandateId) => ({
        url: `/property-details/pre-post`,
        params: {
          mandateId,
        },
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        !!arg ? [{ type: 'Mandate', id: arg }] : ['PropertyDetailsForm'],
    }),
    getMandate: builder.query({
      query: (mandateId) => `/${mandateId}`,
      providesTags: (result, error, arg) => [{ type: 'Mandate', id: arg }],
    }),
    createMandate: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Mandates'],
    }),
    updateMandateContent: builder.mutation({
      query: ({ mandateId, body }) => ({
        url: `${mandateId}/content`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'Mandate', id: arg.mandateId }];
      },
    }),
    updatePropertyDetails: builder.mutation({
      query: ({ mandateId, body }) => ({
        url: `${mandateId}/property-details`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Mandate', id: arg.mandateId },
      ],
    }),
    registerPrePaymentPrestationOrder: builder.mutation({
      query: ({ mandateId, body }) => ({
        url: `${mandateId}/prestations/pre-payment`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['prestation-order'],
    }),
    getPrestationOrderStatus: builder.query({
      query: (mandateId) => ({
        url: `${mandateId}/prestations/prepare-payment`,
        method: 'GET',
      }),
      providesTags: ['prestation-order'],
    }),
    closeMandate: builder.mutation({
      query: ({ mandateId, body }) => ({
        url: `${mandateId}/close`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Mandate', id: arg.mandateId },
      ],
    }),
  }),
});

export const {
  useCreateMandateMutation,
  useUpdateMandateContentMutation,
  useUpdatePropertyDetailsMutation,
  usePrepareMandateFormQuery,
  usePreparePropertyDetailsFormQuery,
  useGetMandatesQuery,
  useGetMandateQuery,
  useRegisterPrePaymentPrestationOrderMutation,
  useGetPrestationOrderStatusQuery,
  useCloseMandateMutation
} = mandateApi;
