import React from "react";
import "./form.css";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from 'react-i18next';

const AcceptanceField = ({ criteriaLabel, className = "", docs, ...props }) => {
  const { t } = useTranslation();

  const criteria = docs ? (
    <a href={docs} className="criteria-docs" target="_blank">
      {criteriaLabel}
    </a>
  ) : (
    criteriaLabel
  );

  return (
    <div className={"field acceptance-field " + (className ?? "")}>
      <FormControlLabel
        control={<Checkbox {...props} />}
        label={
          <span>
            {t("forms.acceptancePrefix")}
            {criteria}
          </span>
        }
      />
    </div>
  );
};

export default AcceptanceField;
