import { CardMedia, Divider } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";

const InvisibleBlockForGrid = styled.div`
  width: 35rem;
`;

const SectionTitle = styled.h4`
  min-width: 35rem;
  @media (max-width: 600px) {
    min-width: initial;
  }
`;

const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 20rem);
  justify-items: center;
  gap: 2rem;
  justify-content: center;
  align-content: start;
`;

const StyledCardMedia = styled(CardMedia)`
  background-color: #f2f2f2;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 13.4rem;
  width: 20rem;
`;

const SkeletonCardMedia = ({ image }) => {
  const [, setImageLoaded] = useState(false);
  return (
    <StyledCardMedia
      style={{ backgroundImage: `url(${image})` }}
      onLoad={() => setImageLoaded(true)}
    />
  );
}

const SeparatorBox = styled(Divider)`
  margin-top: 3em !important;
`;

const TitleBox = styled.p`
  font-size: 1.25rem;
  text-transform: uppercase;
  text-align: center;
`;

const DescriptionBox = styled.p`
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5em;
  margin-left: 1em;
`;

export { InvisibleBlockForGrid, SectionTitle, CardsGrid, SkeletonCardMedia, SeparatorBox, TitleBox, DescriptionBox };