import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import defaultprepareHeaders from "../common/prepare-header";

export const invoiceApi = createApi({
  reducerPath: "invoiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/invoices",
    prepareHeaders: defaultprepareHeaders,
  }),
  endpoints: (builder) => ({
    getInvoices: builder.query({
      query: () => "",
      providesTags: ["list"],
    }),
    generateInvoice: builder.mutation({
      query: (body) => ({
        url: "/pdf",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetInvoicesQuery, useGenerateInvoiceMutation } = invoiceApi;
